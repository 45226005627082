import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionSubtitle: {
    margin:theme.spacing(0, 0, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
}));

export function SectionSubtitle(props) {
  const classes = useStyles();
  return (
    <Typography variant={props.variant || 'subtitle1'} component="h3" className={classes.sectionSubtitle}>{props.children}</Typography>
  );
}
