import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DeliveryIcon from '@material-ui/icons/LocalShippingOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import MedicalIcon from '@material-ui/icons/LocalHospitalOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import AdultUseIcon from '@material-ui/icons/EmojiPeople'

const useStyles = makeStyles((theme) => ({
  categoryContainer: {
    margin: theme.spacing(1, 0),
  },
  categoryItem: {
    width:'100%',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  categoryIcon: {
    marginRight:theme.spacing(1),
  },
  containerHeight: {
    height: 50
  }
}));


export function ListingCategories({listing, restrictHeight = false, ...props}) {
  const classes = useStyles();
  let categoryClasses = `${classes.categoryContainer}`;
  if (restrictHeight) {
    categoryClasses += ` ${classes.containerHeight}`;
  }
  const values = [];
  if (listing && listing.attributes) {
    for (const attribute of listing.attributes) {
      switch (attribute.key) {
        case 'adultUse':
          values.push(<Grid item xs={6} key={attribute.key}><div className={classes.categoryItem}><AdultUseIcon className={classes.categoryIcon} /> Adult Use</div></Grid>);
          break;
        case 'medical':
          values.push(<Grid item xs={6} key={attribute.key}><div className={classes.categoryItem}><MedicalIcon className={classes.categoryIcon} /> Medical</div></Grid>);
          break;
        case 'has_delivery':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div className={classes.categoryItem}><DeliveryIcon className={classes.categoryIcon} /> Delivery</div></Grid>);
          }
          break;
        case 'has_in_store_pickup':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div className={classes.categoryItem}><ShoppingCartIcon className={classes.categoryIcon} /> Online ordering</div></Grid>);
          }
          break;
        case 'has_in_store_shopping':
          if (attribute.value === true) {
            values.push(<Grid item xs={6} key={attribute.key}><div className={classes.categoryItem}><StorefrontIcon className={classes.categoryIcon} /> Storefront</div></Grid>)
          }
          break;
      }
    }
  }

  return (
    <Grid container spacing={0} alignItems="center" className={categoryClasses}>
      { values }
    </Grid>
  )
}
