import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Layout from '../app/layout/Layout';
import { Error, Loading } from '../components/shared';
import { listingService } from '../services';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Grid, List, ListSubheader, ListItem, ListItemText } from '@material-ui/core';
import {ListingVerifiedIcon} from "../components/listings/ListingVerifiedIcon";

const useStyles = makeStyles(theme => ({
  fixedHeightBox: {
    height: 600,
    overflow: 'scroll'
  },
  contentContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
}));

function ListItemLink(props) {
  return <ListItem button component={RouterLink} {...props} />;
}

export function SearchResults(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(true);
  const [listings, setListings] = React.useState([]);

  useEffect(() => {
    listingService.getAll().then(response => {
      setListings(response);
      setLoading(false);
    });
  }, []);

  return (
    <Layout>
      <div className={classes.contentContainer}>
        <h1>Search Results</h1>
        {/* Accounts */}
        <Grid item xs={12} md={6} lg={8}>
          <Box className={classes.fixedHeightBox}>
            <ListSubheader component="div">All listings</ListSubheader>
            <Divider />
            {loading && <Loading>Loading listings...</Loading>}
            {error && <Error>{error}</Error>}
            {listings.length > 0 &&
              <List>
                  {listings.map((listing, index) =>
                      <ListItemLink component={RouterLink} to={`/profile/${listing.id}`} key={listing.id}>
                          <ListItemText primary={listing.name} /> <ListingVerifiedIcon listing={listing} />
                      </ListItemLink>
                  )}
              </List>
            }
          </Box>
        </Grid>
      </div>
    </Layout>
  )
}
