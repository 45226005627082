import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  closeIcon: {
    textAlign: 'right',
    padding: theme.spacing(1)
  }
}));

export default function NavMenu(props) {
  const classes = useStyles();
  const menuItems = [
    {
      title: 'Home',
      icon: 'Home',
      link: '/'
    },
    {
      title: 'Pricing',
      icon: 'Payment',
      link: '/pricing'
    },
    {
      title: 'Checkout',
      icon: 'ShoppingCart',
      link: '/checkout'
    }
  ];

  function getIcon(icon) {
    switch(icon) {
      case 'Home':
        return (<HomeIcon />);
      case 'Payment':
        return (<PaymentIcon />);
      case 'ShoppingCart':
        return (<ShoppingCartIcon />);
      default:
        return ''
    }
  }

  const menuList = (
    <div className={classes.list}>
      <div className={classes.closeIcon}>
        <IconButton size="small" onClick={props.toggleNav}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map((item, index) => (
            <ListItem button component={Link} to={item.link} key={index} >
              <ListItemIcon>{getIcon(item.icon)}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <Drawer anchor="left" open={props.navOpen} onClose={props.toggleNav}>
      {menuList}
    </Drawer>
  );

}
