import React from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import Layout from '../app/layout/Layout';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  contentContainer: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(3, 0, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 2, 6),
    }
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  inactive: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled
  }
}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: [
      {
        title: 'Basic listing',
        active: true
      },
      {
        title: 'Customizeable profile',
        active: false
      },
      {
        title: 'Menu/catalog display',
        active: false
      },
      {
        title: 'Online ordering',
        active: false
      },
      {
        title: 'Help center access',
        active: false
      },
      {
        title: 'Phone & email support',
        active: false
      },
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Pro',
    subheader: 'Most popular',
    price: '399',
    description: [
      {
        title: 'Featured listing',
        active: true
      },
      {
        title: 'Customizeable profile',
        active: true
      },
      {
        title: 'Menu/catalog display',
        active: true
      },
      {
        title: 'Online ordering',
        active: true
      },
      {
        title: 'Help center access',
        active: true
      },
      {
        title: 'Phone & email support',
        active: true
      },
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '999',
    description: [
      {
        title: 'Multiple featured listings',
        active: true
      },
      {
        title: 'Customizeable profile',
        active: true
      },
      {
        title: 'Menu/catalog display',
        active: true
      },
      {
        title: 'Online ordering',
        active: true
      },
      {
        title: 'Help center access',
        active: true
      },
      {
        title: 'Phone & email support',
        active: true
      },
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <title>How Much Does Purple Waze Advertising Cost, Purple Waze Pricing</title>
        <meta name="description" content={`Wondering How Much it costs to advertising or listing your cannabis dispensary on Purple Waze? We have several pricing options to explore.`} />
      </Helmet>
      <Layout>
        {/* Hero unit */}
        <Container maxWidth="sm" className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Purple<span className={classes.textPrimary}>Waze</span> Listing Prices
          </Typography>
          <Typography variant="subtitle2" align="center" color="textSecondary" component="p">
            PurpleWaze offers several pricing packages to our cannabis dispensary customers including free and trial options! We are constantly adding new and improved advertising features to Purple Waze. Learn more about our unique SEO-focused Purple Waze Ad strategy pricing.
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main" className={classes.contentContainer}>
          <Grid container spacing={5} alignItems="center">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mo
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line, index) => (
                        <Typography component="li" variant="body1" align="center" key={index} className={clsx(!line.active && classes.inactive)}>
                          {line.title}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} color="primary">
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Layout>
    </React.Fragment>
  );
}
