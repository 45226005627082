import React, { useState } from 'react';
import config from '../../config';
import {formatting, locationHelper} from '../../helpers';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { ContactForm } from '../shared';
import {ListingMapMarker, ListingRating, ListingHours, ListingOpenClosed, ListingSocialMediaLinks} from './';
import PublicIcon from '@material-ui/icons/Public';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import {ListingVerifiedIcon} from "./ListingVerifiedIcon";


const useStyles = makeStyles(theme => ({
  paper: {
    overflow: 'hidden',
  },
  mapContainer: {
    height:200,
    width: '100%',
  },
  infoContainer: {
    padding: theme.spacing(1, 2),
    background: theme.palette.common.white,
  },
  title: {
    fontWeight: 500,
    marginBottom: 0,
  },
  infoRow: {
    margin: '5px 0px',
  },
  ctaRow: {
    margin:theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  cta: {
    '&:not(last-of-type)': {
      marginRight: 5,
    },
    boxShadow: 'none',
  },
  ctaIcon: {
    marginRight: 3,
    [theme.breakpoints.down('md')]: {
      display:'none',
    },
  },
}));

export function ListingMapBox({listing, ...props}) {
  const classes = useStyles();
  const deviceLocation = useSelector(state => state.location.deviceLocation);
  const [formOpen, setFormOpen] = useState(false);

  const handleContactFormOpen = () => {
    setFormOpen(true);
  };

  const handleContactFormClose = (value) => {
    setFormOpen(false);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.mapContainer}>
          {(listing.latitude && listing.longitude) ?
              <GoogleMapReact
                  bootstrapURLKeys={{ key: config.googleMapsApiKey }}
                  defaultCenter={{
                    lat: parseFloat(listing.latitude),
                    lng: parseFloat(listing.longitude)
                  }}
                  defaultZoom={13}
                  options={{
                    scrollwheel: false,
                  }}
              >
                <ListingMapMarker
                    key={listing.id}
                    lat={listing.latitude}
                    lng={listing.longitude}
                    listing={listing}
                    singleItem
                />
              </GoogleMapReact> :
              <p>Map not available</p>
          }
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoRow}>
            <Typography className={classes.title} variant="h4" component="h3">{listing.displayName ?? listing.name} <ListingVerifiedIcon listing={listing} /></Typography>
          </div>
          <div className={classes.infoRow}>
            <ListingRating
              listing={listing}
              size="small"
              />
          </div>
          <div className={classes.infoRow}>
            <Typography variant="body2" component="p">{listing.address1} {listing.city}, {listing.stateAbbr} {listing.postalCode}</Typography>
          </div>
          <div className={classes.infoRow}>
            <ListingOpenClosed listing={listing} />
          </div>
          <div className={classes.ctaRow}>
            {listing.websiteUrl &&
              <Button className={classes.cta} variant="contained" target="_blank" href={formatting.getLinkToSite(listing.websiteUrl, 'mapbox')}>
                <PublicIcon className={classes.ctaIcon} /> Website
              </Button>
            }
            <Button className={classes.cta} variant="contained" href={`tel:${listing.phoneNumber}`}><PhoneIcon className={classes.ctaIcon} /> Call</Button>
            <Button className={classes.cta} variant="contained" onClick={handleContactFormOpen}><EmailIcon className={classes.ctaIcon} /> Contact</Button>
            {listing.latitude && listing.longitude &&
              <Button className={classes.cta} variant="contained" target="_blank" href={locationHelper.googleDirectionsUrl({origin: deviceLocation && deviceLocation.navigator ? `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}` : null, destination: `${listing.latitude}, ${listing.longitude}`,})}><RoomIcon className={classes.ctaIcon} /> Directions</Button>
            }

            <ContactForm onClose={handleContactFormClose} aria-labelledby="contact-form" open={formOpen} />
          </div>

          <div className={classes.infoRow}>
            <ListingHours noTitle noOpenClosedText listing={listing}>
              <Typography variant="h6" component="h3">Hours</Typography>
            </ListingHours>
          </div>

          <div className={classes.infoRow}>
            <ListingSocialMediaLinks listing={listing} size="small" />
          </div>
        </div>
      </Paper>
    </>
  );
}
