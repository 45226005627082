import React from 'react';
import Link from '@material-ui/core/Link';

export function NotFound() {
  return (
    <div>
      <h1>Page Not Found</h1>
      <Link href="/">Click here</Link> to go back to the dashboard.
    </div>
  );
};
