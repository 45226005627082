import React, { useState } from 'react';
import {Helmet} from "react-helmet";
import Layout from '../app/layout/Layout';
import { Error, Loading, PageTitle } from '../components/shared';
import { listingService } from '../services';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: theme.spacing(2, 2, 2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
}));

export function CountryCategory(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listings, setListings] = useState(null);

  React.useEffect(() => {
    const fetchListings = async () => {
      const results = await listingService.getAll();
      setListings(results);
      setLoading(false);
    };
    fetchListings();
  }, []);

  return (
    <>
      <Layout>
        <div>
          {loading && <Loading>Loading...</Loading>}
          {error && <Error>{error}</Error>}
          {listings &&
            <>
            <Helmet>
              <title>US Cannabis Dispensaries | US Dispensaries</title>
              <meta name="description" content={`Find Directions to US Marijuana Dispensaries. US has ${listings.length} Medical and Recreational Cannabis Dispensaries.`} />
            </Helmet>
            <Container maxWidth="lg" className={classes.contentContainer}>
              <PageTitle noMargin variant="h3">Marijuana dispensaries in {props.match.params.countryAbbr}</PageTitle>
            </Container>
            </>
          }

        </div>
      </Layout>
    </>
  )
}
