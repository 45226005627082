import React from 'react';
import { Link as RouterLink} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListingCategories, ListingRating, ListingOpenClosed } from './';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logo-ghost-icon.svg';
import {locationService} from "../../services";
import {formatting} from "../../helpers";
import {ListingTitle} from "./ListingTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    border: `2px solid ${theme.palette.primary.main}`,
    boxShadow: 'none'
  },
  image: {
    backgroundColor: theme.palette.primary.light
  },
  button: {
    boxShadow: 'none'
  },
  rating: {
    minHeight: 18
  },
  actions: {
    minHeight: 35
  }
}));

export function ListingCard({data, ...props}) {
  const classes = useStyles();
  const listingStateName = locationService.getStateNameByAbbr(data.stateAbbr);
  const urlStateName = formatting.getUrlFriendlyName(listingStateName);
  const urlCityName = formatting.getUrlFriendlyName(data.city);
  const profileUrl = `/dispensary/us/${urlStateName}/${urlCityName}/${data.searchUrlFragment}`;

  return (
    <Card className={classes.root}>
      <CardActionArea component={RouterLink} to={profileUrl}>
          <CardMedia
            component="img"
            alt={data.name}
            height="100"
            image=
              {
                data.logoUrl ?
                  data.logoUrl :
                  data.photoUrls && data.photoUrls.length > 0 ?
                    data.photoUrls[0] :
                    logo
              }
            title={data.name}
            className={classes.image}
          />
          <CardContent>
            <div className={classes.rating}>
              <ListingRating listing={data} size="small" />
            </div>
            <ListingTitle listing={data} variant="h4" component="h3" />
            <Typography variant="body2" color="textSecondary" component="p">
              {data.address1}<br />{data.city}, {data.stateAbbr} {data.postalCode}
            </Typography>
            <ListingCategories listing={data} restrictHeight={true} />
          </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <ListingOpenClosed listing={data} variant="body2" />
      </CardActions>
    </Card>
  );
}
