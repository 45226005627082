import React from 'react';
import { useField } from 'formik';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}));

export const TextInput = ({ label, ...props }) => {
  const classes = useStyles();
   const [field, meta] = useField(props);
   return (
     <>
       <TextField
         variant="outlined"
         margin="normal"
         fullWidth
         label={label}
         {...field}
         {...props} />
       {meta.touched && meta.error ? (
         <div className={classes.error}>{meta.error}</div>
       ) : null}
     </>
   );
 };
