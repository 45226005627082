const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  jointApiKey: process.env.REACT_APP_JOINT_API_KEY,
  tomTomApiUrl: 'https://api.tomtom.com',
  tomTomApiKey: process.env.REACT_APP_TOM_TOM_API_KEY,
  abstractApiKey: process.env.REACT_APP_ABSTRACT_API_KEY,
  abstractApiUrl: process.env.REACT_APP_ABSTRACT_API_URL,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};
export default config;
