import React, { useState } from 'react';
import config from '../../config';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { ContactForm, Breadcrumbs } from '../shared';
import {ListingMapMarker, ListingRating, ListingOpenClosed, ListingCategories, ListingSocialMediaLinks} from './';
import { makeStyles } from '@material-ui/core/styles';
import {formatting, locationHelper} from '../../helpers';
import Box from '@material-ui/core/Box';
import GoogleMapReact from 'google-map-react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PublicIcon from '@material-ui/icons/Public';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import logo from '../../assets/logo-ghost-icon.svg';
import {ListingVerifiedIcon} from "./ListingVerifiedIcon";

const useStyles = makeStyles((theme) => ({
  desktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  mobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  headerContainer: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
  },
  mobileHeaderLower: {
  },
  profileImageContainer: {
    width:'100%',
    marginBottom: theme.spacing(1),
  },
  profileImg: {
    width:'100%',
    height: 200,
    objectFit: 'cover',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      height:150,
    },
    [theme.breakpoints.down('xs')]: {
      height:75,
    }
  },
  profileLogoImg: {
    width:'100%',
    objectFit: 'contain',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      height:150,
    },
    [theme.breakpoints.down('xs')]: {
      height:75,
    }
  },
  mapContainer: {
    height:150,
    width: '100%',
  },
  title: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
  },
  titleRow: {
    margin: 0,
  },
  infoRow: {
    margin: '5px 0px',
  },
  categoriesRow: {
    maxWidth:400,
  },
  ctaRow: {
    margin:theme.spacing(2, 0, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    }
  },
  cta: {
    boxShadow: 'none',
    '& svg': {
      marginRight:5,
    },
    minWidth: 80,
    '&:not(last-of-type)': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      '& span': {
        flexDirection: 'column',
      },
      padding:0,
      minWidth:0,
      background: 'transparent',
      color: theme.palette.primary.main,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      maxWidth: 180,
      '& svg': {
        borderRadius: '50%',
        border: `1px solid ${theme.palette.primary.main}`,
        width: 50,
        height:50,
        padding: 10,
        marginBottom: 5,
        marginRight:0,
      },
      '&:hover': {
        background: 'transparent',
        color: theme.palette.primary.main,
        boxShadow: 'none',
      }
    },
  },
  ctaIcon: {
    display: 'block',
  },
}));

export function ListingProfileHeader({listing, ...props}) {
  const classes = useStyles();
  const deviceLocation = useSelector(state => state.location.deviceLocation);
  const [formOpen, setFormOpen] = useState(false);

  const handleContactFormOpen = () => {
    setFormOpen(true);
  };

  const handleContactFormClose = (value) => {
    setFormOpen(false);
  };

  let linkTarget = '_self',
      linkRel = '',
      linkHref = '#'
  if (listing.websiteUrl) {
    linkTarget = '_blank';
    linkRel = 'noreferrer';
    linkHref = formatting.getLinkToSite(listing.websiteUrl, 'header');
  }

  return (
    <div className={classes.profileHeader}>
      {/* Mobile Map */}
      <div className={clsx(classes.profileMapContainer, classes.mobile)}>
        <a target={linkTarget} rel={linkRel} href={linkHref}>
          <div className={classes.mapContainer}>
            {(listing.latitude && listing.longitude) ?
              <GoogleMapReact
                  bootstrapURLKeys={{ key: config.googleMapsApiKey }}
                  defaultCenter={{
                    lat: parseFloat(listing.latitude),
                    lng: parseFloat(listing.longitude)
                  }}
                  defaultZoom={13}
                  options={{
                    scrollwheel: false,
                  }}
              >
                <ListingMapMarker
                    key={listing.id}
                    lat={listing.latitude}
                    lng={listing.longitude}
                    listing={listing}
                    singleItem
                />
              </GoogleMapReact> :
              <p>Map not available</p>
            }

          </div>
        </a>
      </div>

      {/* Profile Header Details */}
      <Container maxWidth="lg" className={classes.headerContainer}>
        <div className={classes.headerDetails}>
          <div>
            <Breadcrumbs location={props.location} lastText={listing.displayName ? listing.displayName : listing.name} />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4} md={3}>
              <div className={classes.profileImageContainer}>
                <a target={linkTarget} rel={linkRel} href={linkHref}>
                  {(listing.logoUrl) ?
                    <img className={classes.profileLogoImg} src={listing.logoUrl} alt={`${listing.name} cannabis dispensary in ${listing.city} ${listing.state} ${listing.postalCode}`} /> :
                      <img className={classes.profileImg} src={(listing.photoUrls && listing.photoUrls.length > 0) ? listing.photoUrls[0] : logo} alt={`${listing.name} cannabis dispensary in ${listing.city} ${listing.state} ${listing.postalCode}`} />
                  }

                </a>
              </div>
              <ListingSocialMediaLinks listing={listing} justifyContent="center" />
            </Grid>
            <Grid item xs={8} md={9}>
              <div className={classes.detailsContainer}>

                <div className={classes.titleRow}>
                  <Typography className={classes.title} variant="h2" component="h1"><Box fontWeight={500}>{listing.name} <ListingVerifiedIcon listing={listing} /></Box></Typography>
                </div>

                <div className={classes.infoRow}>
                  <ListingRating
                    listing={listing}
                    size="medium"
                    />
                </div>

                <div className={classes.infoRow}>
                  <Typography variant="body1" component="p">{listing.address1} {listing.city}, {listing.stateAbbr} {listing.postalCode}</Typography>
                </div>
                <div className={classes.infoRow}><ListingOpenClosed listing={listing} /></div>

                <div className={classes.desktop}>
                  <div className={clsx(classes.infoRow, classes.categoriesRow)}><ListingCategories listing={listing} /></div>

                  <div className={classes.ctaRow}>
                    {listing && listing.websiteUrl &&
                      <Button className={classes.cta} variant="outlined" color="primary" target={linkTarget} href={linkHref}><PublicIcon className={classes.ctaIcon}/> Website</Button>
                    }
                    <Button className={classes.cta} variant="outlined" color="primary" href={`tel:${listing.phoneNumber}`}><PhoneIcon className={classes.ctaIcon} /> Call</Button>
                    <Button className={classes.cta} variant="outlined" color="primary" onClick={handleContactFormOpen}><EmailIcon className={classes.ctaIcon} /> Contact</Button>
                    {listing.latitude && listing.longitude &&
                      <Button className={classes.cta} variant="outlined" color="primary" target="_blank" href={locationHelper.googleDirectionsUrl({origin: deviceLocation && deviceLocation.navigator ? `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}` : null, destination: `${listing.latitude}, ${listing.longitude}`,})}><RoomIcon className={classes.ctaIcon} /> Directions</Button>
                    }
                    <ContactForm onClose={handleContactFormClose} aria-labelledby="contact-form" open={formOpen} />
                  </div>
                </div>

              </div>
            </Grid>
          </Grid>
        </div>

         {/* Mobile CTA Row */}
        <div className={clsx(classes.mobileHeaderLower, classes.mobile)}>
          <div className={clsx(classes.infoRow, classes.categoriesRow)}><ListingCategories listing={listing} /></div>

          <div className={classes.ctaRow}>
            {listing && listing.websiteUrl &&
              <Button className={classes.cta} variant="contained" color="secondary" target={linkTarget} href={linkHref}><PublicIcon className={classes.ctaIcon} /> Website</Button>
            }
            <Button className={classes.cta} variant="contained" color="secondary" href={`tel:${listing.phoneNumber}`}><PhoneIcon className={classes.ctaIcon} /> Call</Button>
            <Button className={classes.cta} variant="contained" color="secondary" onClick={handleContactFormOpen}><EmailIcon className={classes.ctaIcon} /> Contact</Button>
            <Button className={classes.cta} variant="contained" color="secondary" target="_blank" href={locationHelper.googleDirectionsUrl({origin: deviceLocation && deviceLocation.navigator ? `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}` : null, destination: `${listing.latitude}, ${listing.longitude}`,})}><RoomIcon className={classes.ctaIcon} /> Directions</Button>
            <ContactForm onClose={handleContactFormClose} aria-labelledby="contact-form" open={formOpen} />
          </div>
        </div>

      </Container>
    </div>
  );

}
