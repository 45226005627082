import React, { useState, useRef, useLayoutEffect, memo } from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';
import { listingsConstants } from '../../constants';
import { locationService } from '../../services';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { ListingMapMarker } from './';
import { makeStyles, alpha } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height:800,
    width: '100%',
    position: 'relative',
  },
  resultsCount: {
    position: 'absolute',
    top: 10,
    left: 10,
    padding: theme.spacing(1),
    background: alpha(theme.palette.common.black, 0.6),
    borderRadius: theme.borderRadius,
    zIndex: 9,
  },
  resultsText: {
    color: theme.palette.common.white,
  }
}));

function CustomMap(props) {
  const classes = useStyles();
  const mapRef = useRef();
  const mapData = useSelector(state => state.listings.data.mapLocations);
  const itemCount = useSelector(state => state.listings.meta.itemCount);
  const [mapCenter, setMapCenter] = useState(null);
  const [mapZoom, setMapZoom] = useState(null);

  useLayoutEffect(() => {
    let mapDimensions = {};
    if (mapRef.current) {
      mapDimensions = {
        width: mapRef.current.offsetWidth,
        height: mapRef.current.offsetHeight
      };
    }

    function getMapBounds(location) {
      let nw = {
        lat: location.viewport.topLeftPoint.lat,
        lng: location.viewport.topLeftPoint.lon,
      };
      let se = {
        lat: location.viewport.btmRightPoint.lat,
        lng: location.viewport.btmRightPoint.lon,
      }

      if (location.viewport.topLeftPoint.lat === location.viewport.btmRightPoint.lat && location.viewport.topLeftPoint.lon === location.viewport.btmRightPoint.lon) {
        nw.lat += 0.1;
        nw.lng -= 0.1;

        se.lat -= 0.1;
        se.lng += 0.1;
      }

      return {
        nw,
        se
      };
    }

    const bounds = props.location ? getMapBounds(props.location) : null;
    const size = {
      width: mapDimensions.width || 800,
      height: mapDimensions.height || 800,
    };

    const {center, zoom} = fitBounds(bounds, size);
    setMapCenter(center);
    setMapZoom(zoom);

  }, [props.location]);

  /**
   * Outline a location search
   * @param  {[type]} map  [description]
   * @param  {[type]} maps [description]
   * @return {[type]}      [description]
   */
  function highlightArea({map, maps}) {
    mapRef.current = map;
    if (props.location.entityType !== 'PostalCodeArea') {
      locationService.getPolygonCoords(props.location)
        .then(response => {
          const geoJSONDataChunk = response;
          const geoConf = {
            "type": "FeatureCollection",
            "features": [
              { "type": "Feature",
                "geometry": geoJSONDataChunk.geojson,
                "id": "city"
              }
            ]
          };
          const cityData = new maps.Data();
          cityData.addGeoJson(geoConf, "city");
          cityData.setStyle({
              fillColor: '#736ADC',
              fillOpacity: 0,
              strokeColor: '#736ADC',
              strokeOpacity: 0.8,
              strokeWeight: 3,
            });
          cityData.setMap(map);
        });
    }
  }

    /**
     * Sets the distance to the mouse needed for marker hover
     * Used for markers in a cluster so you can hover over each one
     * @param markerPos
     * @param mousePos
     * @param markerProps
     * @returns {number}
     */
  function distanceToMouse(markerPos, mousePos, markerProps) {
    const x = markerPos.x;
    // custom marker so transform to a more central point in the marker img
    const y = markerPos.y - 45 / 2;
    // Set the coefficient for hoverability
    // Can be adjusted to make certain elements more hoverable
    const distanceCoef = 1.5;
    // Calculate and return the distance to the mouse
    return distanceCoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
 }

  const markers = mapData.length > 0 &&
    mapData.map((listing, index) => {
        if (listing.latitude && listing.longitude) {
            return (
                <ListingMapMarker
                    key={`${index}-map`}
                    lat={listing.latitude}
                    lng={listing.longitude}
                    listing={listing}
                    isHovered={props.hoveredItem === listing.id ? true : false}
                />
            );
        }
    });

  return (
    <>
      <div ref={mapRef} className={classes.mapContainer}>
        <Box className={classes.resultsCount}>
          <Typography className={classes.resultsText} variant="body2" component="span">{itemCount > listingsConstants.MAX_RESULTS ? `Showing ${listingsConstants.MAX_RESULTS} of ${itemCount} results` : `Showing ${itemCount} results`}</Typography>
        </Box>
      {mapCenter && mapZoom &&
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.googleMapsApiKey }}
          center={mapCenter}
          zoom={mapZoom}
          options={{
            scrollwheel: false,
          }}
          hoverDistance={20}
          distanceToMouse={distanceToMouse}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={ highlightArea }
          >
          {markers}
        </GoogleMapReact>
      }
      </div>
    </>
  )
}

export const ListingsMap = memo(CustomMap);
