import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { LocationSearch } from './';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme =>({
  root:{

  },
  desktop: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  location: {
    display:'inline-block',
    verticalAlign: 'middle',
    textAlign: 'right',
    width: '100%',
    minWidth: 150,
  },
  changeLink: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      marginLeft: theme.spacing(0),
    },

  },
  markerIcon: {
    display:'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    top:-3,
  },
  search: {
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    minWidth: 300,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      display: 'block',
    },
  },
}));

export function LocationDisplay(props) {
  const classes = useStyles();
  const location = useSelector(state => state.location.selectedLocation);
  const [changeLocation, setChangeLocation] = useState(false);

  return (
    <>
      <Box className={classes.root}>
        {location && !changeLocation &&
          <>
            <div className={classes.desktop}>
              <Typography variant="body1" component="p" className={classes.location}>
                <RoomOutlinedIcon className={classes.markerIcon} color="primary" fontSize="large" /> {location.address.freeformAddress}
                <Link
                  className={classes.changeLink}
                  component="button"
                  variant="body2"
                  onClick={() => {setChangeLocation(true)}}>
                  Change location
                </Link>
              </Typography>
            </div>

            <div className={classes.mobile}>

                <Link
                  className={classes.changeLink}
                  component="button"
                  variant="body2"
                  onClick={() => {setChangeLocation(true)}}>
                  <RoomOutlinedIcon className={classes.markerIcon} color="primary" fontSize="small" /> {location.address.freeformAddress}
                </Link>

            </div>
          </>
        }
        {(!location || changeLocation) &&
          <div className={classes.search}>
            <LocationSearch noShadow size="small" variant="grey" onSelect={() => setChangeLocation(false)}/>
          </div>
        }
      </Box>
    </>
  );
}
