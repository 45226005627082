import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {ListingMapBox, ListingHours, ListingProfileTabTtitle } from './';
import { formatting } from '../../helpers';
import { useWindowDimensions } from '../../hooks';

const useStyles = makeStyles(theme => ({
  title: {
    margin:theme.spacing(0, 0, 1),
  },
  content: {
    order:1,
    [theme.breakpoints.down('sm')]: {
      order: 2
    },
  },
  map: {
    order:2,
    [theme.breakpoints.down('sm')]: {
      order: 1
    },
  },
  row: {
    margin: theme.spacing(0, 0, 3),
  },
}));

export function ListingAbout({listing, ...props}) {
  const classes = useStyles();
  const windowDimensions = useWindowDimensions();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7} lg={8} className={classes.content}>
          <ListingProfileTabTtitle>About</ListingProfileTabTtitle>
          <div className={classes.row}>
            <p className={classes.aboutContent}>
              {listing.name} is a {listing.city}, {listing.stateAbbr}-based Cannabis Dispensary.
              Come visit us today at {listing.address1} {listing.city}, {listing.stateAbbr} {listing.postalCode} or call us <a href={`tel:${listing.phoneNumber}`}>{formatting.formatPhoneNumber(listing.phoneNumber)}</a> to discuss what you need.
            </p>
          </div>
          <div className={classes.row}>
            <ListingHours id="about-hours" listing={listing} />
          </div>
        </Grid>
        {windowDimensions.width > 600 &&
          <Grid item xs={12} md={5} lg={4} className={classes.map}>
            <ListingMapBox listing={listing} />
          </Grid>
        }
      </Grid>
    </>
  )
}
