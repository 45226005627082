import React from 'react';
import { ListingReviewListItem } from './';
import List from '@material-ui/core/List';

export function ListingReviewsList(props) {
  const { reviews, ...others} = props;
  return (
    <List disablegutters="true" {...others}>
      {reviews.map((review, index) => (
        <ListingReviewListItem review={review} key={index} />
      ))}
    </List>
  );
}
