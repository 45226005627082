import React from 'react';
import config from '../../config';
import { useSelector } from 'react-redux';
import { formatting, locationHelper } from '../../helpers';
import { ListingMapBox, ListingHours, ListingMapMarker, ListingProfileTabTtitle } from './';
import { Helmet } from 'react-helmet';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius:theme.borderRadius,
  },
  title: {
    margin:theme.spacing(0, 0, 1),
  },
  mapContainer: {
    height:600,
    width: '100%',
  },
  row: {
    display: 'block',
    margin: theme.spacing(0, 0, 3),
  },
  ctaRow: {
    margin: theme.spacing(3, 0),
  },
}));

export function ListingDirections({listing, ...props}) {
  const classes = useStyles();
  const deviceLocation = useSelector(state => state.location.deviceLocation);
  const useApi = props.useApi || false;

  /**
   * Calculates and displays directions to business
   * @param map
   * @param maps
   */
  function calculateAndDisplayRoute({map, maps}) {
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();

    directionsRenderer.setMap(map);
    directionsRenderer.setPanel(document.getElementById("directions"));

    directionsService.route({
      origin: `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}`,
      destination: `${listing.latitude}, ${listing.longitude}`,
      travelMode: 'DRIVING'
    }, (response, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(response);
        console.log(response.routes[0].overview_path, 'route')
        const routePolyline = new maps.Polyline({
          path: response.routes[0].overview_path
        });
        routePolyline.setMap(map);
      } else {
        window.alert('Directions request failed due to ' + status);
        }
      });
  }

  return (
    <>
      <Helmet>
        <title>Directions to {listing.displayName ?? listing.name}, {listing.city} {listing.postalCode}</title>
        <meta name="description" content={`Get directions to ${listing.name}. Find out how far away ${listing.name} is from you.`} />
      </Helmet>
      <Box className={classes.box}>
        {!useApi &&
          <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} className={classes.content}>
              <ListingProfileTabTtitle>Directions</ListingProfileTabTtitle>
              <div className={classes.row}>
                <p className={classes.aboutContent}>
                  {listing.displayName ?? listing.name} is a {listing.city}, {listing.stateAbbr}-based Cannabis Dispensary.
                  Come visit us today at {listing.address1} {listing.city}, {listing.stateAbbr} {listing.postalCode} or call us <a href={`tel:${listing.phoneNumber}`}>{formatting.formatPhoneNumber(listing.phoneNumber)}</a> to discuss what you need.
                </p>
                <div className={classes.ctaRow}>
                  {listing.latitude && listing.longitude &&
                    <Button size="large" variant="contained" color="primary" target="_blank" href={locationHelper.googleDirectionsUrl({origin: deviceLocation && deviceLocation.navigator ? `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}` : null, destination: `${listing.latitude}, ${listing.longitude}`,})}><RoomIcon /> Get directions</Button>
                  }

                </div>
              </div>
              <div className={classes.row}>
                <ListingHours listing={listing} />
              </div>
            </Grid>
            <Grid item xs={12} md={4} className={classes.map}>
              <ListingMapBox listing={listing} />
            </Grid>
          </Grid>
          </>

        }
        {useApi &&
          <>
            {deviceLocation && listing.latitude && listing.longitude &&
              <>
                <div className={classes.mapContainer}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: config.googleMapsApiKey }}
                    defaultCenter={{
                      lat: parseFloat(listing.latitude),
                      lng: parseFloat(listing.longitude)
                    }}
                    defaultZoom={15}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={ calculateAndDisplayRoute }
                    >
                      <ListingMapMarker
                        key={listing.id}
                        lat={listing.latitude}
                        lng={listing.longitude}
                        listing={listing}
                      />
                      <ListingMapMarker
                        key={99}
                        lat={deviceLocation.navigator.latitude}
                        lng={deviceLocation.navigator.longitude}
                      />
                  </GoogleMapReact>
                </div>
                <div id="directions"></div>
              </>
            }
            {!deviceLocation &&
              <>
                <Typography variant="subtitle1" component="h4">Please enable location sharing on your device.</Typography>
              </>
            }

          </>
        }


      </Box>
    </>
  )
}
