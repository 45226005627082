import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import NavMenu from './NavMenu'
import { Logo } from '../../components/shared';
import { LocationDisplay, LocationSearch } from '../../components/location';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  desktop: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 48,
    },
  },
  navBarContainer: {
    backgroundColor: theme.palette.common.white,
    color: theme.bodyFontColor,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      marginLeft: -52,
      display: 'flex',
      justifyContent: 'center',
      flexGrow: 1,
      padding: 0,
    }
  },
  location: {
    justifyContent: 'flex-end',
  },
  searchBar: {
    justifyContent: 'center',
  }

}));

export default function Header() {
  const classes = useStyles();
  const [navOpen, setNavOpen] = React.useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <div className={classes.grow}>
        <NavMenu navOpen={navOpen} toggleNav={toggleNav}/>
        <AppBar position="fixed" className={classes.navBarContainer}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={toggleNav}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.logo}>
              <Link to="/">
                <Logo logoSize={220} />
              </Link>
            </div>
            <div className={clsx(classes.grow, classes.desktop)} />
            <div className={clsx(classes.location, classes.desktop)}>
              <LocationDisplay />
            </div>
          </Toolbar>
          <Box py={1} px={2} className={clsx(classes.searchBar, classes.mobile)}>
              <LocationSearch id="nav-location-search" noShadow size="small" variant="grey" />
          </Box>
        </AppBar>
    </div>
  );
}
