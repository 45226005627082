import React, { useState, useCallback } from 'react';
import { ListingsList, ListingsMap } from './';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height:800,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    }
  },
  listContainer: {
    height:800,
    padding:0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display:'none',
    },
    [theme.breakpoints.down('sm')]: {
      height:'auto',
      overflowY: 'hidden',
    }
  },
  list: {
    padding:0,
  },
}));

export function ListingsSearchResults({ location, ...props }) {
  const classes = useStyles();
  const [hoveredItem, setHoveredItem] = useState(null);
  //const mapData = useSelector(state => state.listings.data.mapLocations);


  const handleItemHover = useCallback((id) => {
      setHoveredItem(id);
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} lg={3} className={classes.listContainer} id="listings-list">
          <ListingsList onItemHover={handleItemHover} categoryType={props.categoryType} />
        </Grid>
        <Grid item xs={12} md={8} lg={9} className={classes.mapContainer}>
          <ListingsMap location={location} hoveredItem={hoveredItem} />
        </Grid>
      </Grid>
    </>
  )
}
