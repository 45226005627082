import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { listingService } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import { Loading } from '../shared';
import { ListingProfileTabTtitle, ListingRating, ListingReviewSitesSummary, ListingReviewsList } from './';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import googleLogo from '../../assets/google-logo.png';
import yelpLogo from '../../assets/yelp-logo.png';

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: 400,
  },
  tabBar: {
    borderTop: theme.border,
    borderBottom: theme.border,
    boxShadow: 'none',
  },
  tab: {
    '&:hover': {
      color: theme.palette.primary.main,
      //borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  reviewSummaryContainer: {
    padding: theme.spacing(1, 0),
  },
  reviewSiteSummary: {
    width: '100%',
    '& img': {
      width: '100%',
      maxWidth: 100,
      marginBottom: 6,
    }
  }
}));

function a11yProps(index) {
  return {
    id: `review-site-tab-${index}`,
    'aria-controls': `review-site-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`review-site-tabpanel-${index}`}
      aria-labelledby={`review-site-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function ListingReviews({listing, ...props}) {
  const classes = useStyles();
  const [reviews, setReviews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const fetchReviews  = async () => {
      const reviews = await listingService.getReviews(listing.id);
      setReviews(reviews);
      setTabValue(0);
      setLoading(false);
    }
    fetchReviews();
  }, [listing.id]);

  // Build an array for the reviews tabs and objects.
  const tabArray = [];
  const tabPanelArray = [];
  let i = 0;
  if (!loading) {
    if (reviews.googleReviews.length > 0) {
      tabArray.push(
        <Tab key="googleTab" classes={{wrapper: classes.tab}} label="Google" {...a11yProps(i)} />
      );
      tabPanelArray.push(
        <TabPanel key="googlePanel" value={tabValue} index={i}>
          <Box className={classes.reviewSummaryContainer} display="flex" justifyContent="flex-start">
            <div className={classes.reviewSiteSummary}>
              <img src={googleLogo} alt="Google Logo" />
              <ListingRating listing={listing} site="google" size="medium" summary noPrice />
            </div>
          </Box>
          <ListingReviewsList reviews={reviews.googleReviews} />
        </TabPanel>
      );
      i++;
    }
    if (reviews.yelpReviews.length > 0) {
      tabArray.push(
        <Tab key="yelpTab" classes={{wrapper: classes.tab}} label="Yelp" {...a11yProps(i)} />
      );
      tabPanelArray.push(
        <TabPanel key="yelpPanel" value={tabValue} index={i}>
          <Box className={classes.reviewSummaryContainer} display="flex" justifyContent="flex-start">
            <div className={classes.reviewSiteSummary}>
              <img src={yelpLogo} alt="Yelp Logo" />
              <ListingRating listing={listing} site="yelp" size="medium" summary noPrice />
            </div>
          </Box>
          <ListingReviewsList reviews={reviews.yelpReviews} />
        </TabPanel>
      );
      i++;
    }
  }
  return (
    <>
      <Helmet>
        <title>{listing.name}® Reviews, {listing.city} Cannabis Dispensary Testimonials</title>
        <meta name="description" content={`Read reviews of ${listing.name} a Cannabis Dispensary in ${listing.city}, ${listing.stateAbbr}. Read our customer reviews and provide feedback on your experience at ${listing.name}.`} />
      </Helmet>
      <ListingProfileTabTtitle>Reviews</ListingProfileTabTtitle>
      <Box className={classes.box}>
        {loading && <Loading message="Gathering reviews..." />}

        {!loading && reviews.googleReviews.length === 0 && reviews.yelpReviews.length === 0 &&
          <p>No reviews found.</p>
        }

        {!loading && (reviews.googleReviews.length > 0 || reviews.yelpReviews.length > 0) &&
          <>
            <ListingReviewSitesSummary listing={listing} handleClick={handleTabChange}/>

            <AppBar position="static" color="transparent" className={classes.tabBar}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="review sites tabs"
                indicatorColor="primary"
                className={classes.tabsContainer}
              >
                {tabArray}
                {/*<Tab classes={{wrapper: classes.tab}} label="Google" {...a11yProps(0)} disabled={reviews.googleReviews.length === 0 ? true : false} />*/}
                {/*<Tab classes={{wrapper: classes.tab}} label="Yelp" {...a11yProps(1)} disabled={reviews.yelpReviews.length === 0 ? true : false} />*/}
              </Tabs>
            </AppBar>

            {tabPanelArray}
          </>
        }

      </Box>
    </>
  )
}
