import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  overflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export function ListingTitle({listing, variant, component, ...props}) {
  const classes = useStyles();
  const name = listing.displayName ? listing.displayName : listing.name;

  return (
    <Tooltip title={listing.name}>
      <Typography gutterBottom variant={variant} component={component} className={classes.overflow}>
        { name }
      </Typography>
    </Tooltip>
  )

}
