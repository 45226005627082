import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    margin:theme.spacing(0, 0, 1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem',
    }
  },
}));

export function SectionTitle(props) {
  const classes = useStyles();
  return (
    <Typography variant={props.variant || 'h2'} component="h2" className={classes.sectionTitle}>{props.children}</Typography>
  );
}
