import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { listingService } from '../services';

interface ResponseMeta {
  page?: number;
  take?: number;
  itemCount?: number;
  pageCount?: number;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

interface ResponseData {
  businesses: Array<any>;
  mapLocations: Array<any>;
}

interface RequestConfig extends ResponseMeta {
    requestType?: string;
    stateAbbr?: string;
    includeMap?: boolean;
    city?: string;
    postalCode?: string;
}

interface ListingsData {
  data: ResponseData;
  meta: ResponseMeta;
  requestConfig: RequestConfig;
}

interface ListingsState extends ListingsData {
  selectedListing: number | null;
  isLoading: boolean;
  error: string | null;
  didInvalidate: boolean,
}

const initialState: ListingsState = {
    selectedListing: null,
    isLoading: false,
    error: null,
    didInvalidate: false,
    data: {
      businesses:[],
      mapLocations: [],
    },
    meta: {},
    requestConfig: {}
}

export const fetchListings = createAsyncThunk(
  'listings/fetchListings',
  async (requestConfig: RequestConfig) => {
    let response: any;
    switch(requestConfig.requestType) {
      case 'state':
        response = await listingService.getAllByState(requestConfig);
        break;
      case 'city':
        response = await listingService.getAllByCity(requestConfig);
        break;
      case 'postalCode':
         response = await listingService.getAllByPostalCode(requestConfig);
        break;
      default:
        response = await listingService.getAllByCity(requestConfig);
    }
    const payload: ListingsData = {
      data: {
        businesses: response.data.businesses || [],
        mapLocations: response.data.mapLocations || []
      },
      meta: response.meta,
      requestConfig
    };
    return payload;
  }
);

export const listingsSlice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    clearListings(state) {
      state.data.businesses = [];
      state.data.mapLocations = [];
      state.meta = {};
      state.requestConfig = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchListings.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.data && action.payload.data.businesses) {
          state.data.businesses = (action.payload.meta && action.payload.meta.page && action.payload.meta.page > 1) ? [...state.data.businesses, ...action.payload.data.businesses] : action.payload.data.businesses;
        }

        if (action.payload.data.mapLocations && action.payload.data.mapLocations.length > 0) {
          state.data.mapLocations = action.payload.data.mapLocations;
          state.meta.itemCount = action.payload.meta.itemCount;
        }
        // Don't unnecessarily update item count and cause unnecessary re-renders
        const { itemCount, ...metaWithoutItemCount} = action.payload.meta;
        state.meta = Object.assign({}, state.meta, metaWithoutItemCount);

        state.requestConfig = action.payload.requestConfig;
        //state.error = null;
      })
      .addCase(fetchListings.rejected, (state, action) => {
        state.isLoading = false;
          state.error = action.error.message ?? null;
      });
  }
});
export const { clearListings } = listingsSlice.actions

export default listingsSlice.reducer;
