import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Route, Switch, Link as RouterLink } from 'react-router-dom';
import Layout from '../app/layout/Layout';
import { Error, Loading } from '../components/shared';
import { ListingProfileHeader, ListingAbout, ListingDirections, ListingReviews, ListingMenu } from '../components/listings';
import { listingService } from '../services';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding:theme.spacing(3, 0),
  },
  tabsContainer: {
    borderTop: theme.border,
    borderBottom: theme.border,
  },
  tab: {
    padding:theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
      //borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    [theme.breakpoints.down('xs')]: {
      width: '25%',
    }
  }
}));

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

export function ListingProfile(props) {
  const classes = useStyles();
  const listingUrlFragment = props.match.params.listingUrlFragment;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listing, setListing] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const location = props.location;

  useEffect(() => {
    listingService.getOneByUrlFragment(listingUrlFragment).then(response => {
      if (!response) {
        return props.history.push('/404');
      }
      const listing = response;
      setListing(listing);
      setLoading(false);
    });
  }, [listingUrlFragment, props.history]);

  useEffect(() => {
    const profileTabs = ['/about', '/menu', '/reviews', '/directions'];
    const tabUrl = props.location.pathname.replace(props.match.url, '');
    if (tabUrl !== '' && profileTabs.includes(tabUrl)) {
      setTabValue(profileTabs.indexOf(tabUrl));
    } else {
      setTabValue(0);
    }
  }, [props.location.pathname, props.match.url]);

  return (
    <>
      <Layout>
        <div>
          {loading && <Loading message="Loading..." />}
          {error && <Error>{error}</Error>}
          {listing &&
            <>
              <Helmet>
                <title>{listing.name}® Cannabis Dispensary {listing.city} {listing.stateAbbr} {listing.postalCode}</title>
                <meta name="description" content={`${listing.name} is your ${listing.city}, ${listing.stateAbbr} ${listing.postalCode} Marijuana Dispensary. See current offers, directions, reviews, and contact info for ${listing.name} ${listing.postalCode}`} />
              </Helmet>

              <ListingProfileHeader listing={listing} location={location} />

              <div className={classes.tabsContainer}>
                <Container maxWidth="lg">
                  <Tabs
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="dispendary profile navigation tabs"
                    value={tabValue}
                  >
                    <Tab className={classes.tab} label="About" component={RouterLink} to={`${props.match.url}/about`} {...a11yProps(0)} />
                    <Tab className={classes.tab} label="Menu" component={RouterLink} to={`${props.match.url}/menu`} {...a11yProps(1)} />
                    <Tab className={classes.tab} label="Reviews" component={RouterLink} to={`${props.match.url}/reviews`} {...a11yProps(3)} />
                    <Tab className={classes.tab} label="Directions" component={RouterLink} to={`${props.match.url}/directions`} {...a11yProps(4)} />
                  </Tabs>
                </Container>
              </div>
              <Switch>
                <Route exact path={[`${props.match.path}`,`${props.match.path}/about`]} render={(props) => (
                  <div className={classes.sectionContainer}>
                    <Container maxWidth="lg">
                      <ListingAbout {...props} listing={listing} />
                    </Container>
                  </div>
                )} />
                <Route path={`${props.match.path}/menu`} render={(props) => (
                  <div className={classes.sectionContainer}>
                    <Container maxWidth="lg">
                      <ListingMenu {...props} listing={listing} />
                    </Container>
                  </div>
                )} />
                <Route path={`${props.match.path}/reviews`} render={(props) => (
                  <div className={classes.sectionContainer}>
                    <Container maxWidth="lg">
                      <ListingReviews {...props} listing={listing} />
                    </Container>
                  </div>
                )} />
                <Route path={`${props.match.path}/directions`} render={(props) => (
                  <div className={classes.sectionContainer}>
                    <Container maxWidth="lg">
                      <ListingDirections {...props} listing={listing} />
                    </Container>
                  </div>
                )} />
              </Switch>
            </>
          }

        </div>
      </Layout>
    </>
  )
}
