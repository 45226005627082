import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme, props) => ({
  pageTitle: (props) => ({
    margin:(props.noMargin) ? theme.spacing(0, 0, 0) : theme.spacing(0, 0, 2),
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      margin:(props.noMargin) ? theme.spacing(0, 0, 0) : theme.spacing(0, 0, 1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem'
    }
  }),
}));

export function PageTitle(props) {
  const classes = useStyles(props);
  return (
    <Typography variant={props.variant || 'h1'} component="h1" className={classes.pageTitle}>{props.children}</Typography>
  );
}
