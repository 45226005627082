import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import googleLogo from '../../assets/google-logo.png';
import yelpLogo from '../../assets/yelp-logo.png';

const useStyles = makeStyles(theme => ({
  siteSummaryContainer: {
    padding: theme.spacing(0, 0, 1),
  },
  siteSummary: {
    cursor: 'pointer',
    padding: theme.spacing(1, 1),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      width: '100%',
      maxWidth: 75,
    },
    '&:not(:last-of-type)': {
      borderRight: theme.border,
    },
  },
  rating: {
    fontSize: '1.5rem',
    marginTop: 6,
  },
  reviewCount: {
    fontSize: 12,
    marginTop: 8,
  },
}));

export function ListingReviewSitesSummary(props) {
  const { listing, ...others } = props;
  const classes = useStyles();

  return (
    <Box className={classes.siteSUmmaryContainer} display="flex" justifyContent="flex-start" {...others}>
      {listing.googleRating &&
        <div className={classes.siteSummary} onClick={() => props.handleClick(null, 0)}>
          <img src={googleLogo} alt="Google Logo" />
          <Typography className={classes.rating} variant="h3" component="div">{listing.googleRating}/5</Typography>
          <Typography className={classes.reviewCount} variant="subtitle2" component="div">{listing.googleReviews} review{listing.googleReviews> 1 && 's'}</Typography>
        </div>
      }

      {listing.yelpRating &&
        <div className={classes.siteSummary} onClick={() => props.handleClick(null, 1)}>
          <img src={yelpLogo} alt="Yelp Logo" />
          <Typography className={classes.rating} variant="h3" component="div">{listing.yelpRating}/5</Typography>
          <Typography className={classes.reviewCount} variant="subtitle2" component="div">{listing.yelpReviewCount} review{listing.yelpReviewCount > 1 && 's'}</Typography>
        </div>
      }
    </Box>
  )
}
