import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Logo, NewsLetterForm } from '../../components/shared';
import Copyright from './Copyright';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    padding: theme.spacing(2, 3),
    borderTop: theme.border,
  },
  logoSection: {
    maxWidth: 300,
    margin: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth:'100%',
    }
  },
  footerFormTitle: {
    margin: theme.spacing(1, 0),
  }
}));

export default function Footer(props) {
  const classes = useStyles();

  const footerMenuItems = [
    {
      id: 1,
      title: 'Sign up',
      items: [
        {
          id: 10,
          title: 'Add your business',
          url: '/'
        },
        {
          id: 11,
          title: 'Claim your business',
          url: '/'
        },
        {
          id: 12,
          title: 'Advertise with us',
          url: '/'
        },
        {
          id: 13,
          title: 'Build your website',
          url: '/'
        },
        {
          id: 14,
          title: 'Contact us',
          url: '/'
        }
      ],
    },
    {
      id: 2,
      title: 'Company',
      items: [
        {
          id: 20,
          title: 'Our story',
          url: '/'
        },
        {
          id: 21,
          title: 'Our founders',
          url: '/'
        },
        {
          id: 22,
          title: 'Rank Really High®',
          url: '/'
        },
        {
          id: 23,
          title: 'Careers',
          url: '/'
        },
        {
          id: 24,
          title: 'Contact us',
          url: '/'
        }
      ],
    },
    {
      id: 3,
      title: 'Investors',
      items: [
        {
          id: 30,
          title: 'Make an inquiry',
          url: '/'
        },
        {
          id: 31,
          title: 'See partners',
          url: '/'
        },
        {
          id: 32,
          title: 'Contact us',
          url: '/'
        },
      ],
    },
    {
      id: 4,
      title: 'Social',
      items: [
        {
          id: 40,
          title: 'Facebook',
          url: 'https://www.facebook.com/PurpleWaze-106557114756889',
          icon: <FacebookIcon />
        },
        {
          id: 41,
          title: 'LinkedIn',
          url: 'https://www.linkedin.com/company/purplewaze/',
          icon: <LinkedInIcon />
        },
        {
          id: 42,
          title: 'Twitter',
          url: '/',
          icon: <TwitterIcon />
        },
        {
          id: 43,
          title: 'Instagram',
          url: '/',
          icon: <InstagramIcon />
        },
      ],
    },
  ]

  return (
    <div className={classes.footerContainer}>
      <Grid container alignItems="center" spacing={3}>
        <Grid item xs={12} md={4}>
          <Box className={classes.logoSection}>
            <Logo logoSize={250} />
            <NewsLetterForm />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {footerMenuItems.map((item, index) => (
              <Grid item xs={6} sm={3} key={item.id}>
                <Typography variant="h5" component="h5">{item.title}</Typography>
                <List>
                {item.items.map((link, index) => (
                  <ListItem key={link.id} button disableGutters component={Link} to={link.url}>{link.icon || ''} {link.title}</ListItem>
                ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Box p={5}>
        <Copyright />
      </Box>
    </div>
  )
}
