import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import { locationService } from '../services';
import Layout from '../app/layout/Layout';
import { Breadcrumbs, Error, Loading, PageTitle, PageSubtitle } from '../components/shared';
import { ListingsSearchResults } from '../components/listings';
import { fetchListings, clearListings } from '../redux/listingsSlice';
import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
  resultsContainer: {
    padding: 0,
    borderTop: theme.border,
    borderBottom: theme.border,
  },
  textPrimary: {
    color: theme.palette.primary.main,
  }
}));

export function StateCategory(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(state => state.listings.error);
  const mapData = useSelector(state => state.listings.data.mapLocations);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [stateLocation, setStateLocation] = useState(null);
  const stateName = props.match.params.stateName;

  React.useEffect(() => {

    locationService.getStateDetails(stateName)
      .then(response => {
        if (!response) {
          return props.history.push('/404');
        }
        setStateLocation(response);
        setLocationLoading(false);

        const requestConfig = {
          requestType: 'state',
          stateAbbr: response.address.countrySubdivision,
          includeMap: true,
        };
        dispatch(fetchListings(requestConfig))
          .then(() => {
            setListingsLoading(false);
          });
      })
      .catch(error => console.log(error));

      return () => {
        dispatch(clearListings());
      }
  }, [props.history, stateName, dispatch])

  return (
    <>
      <Layout>
        <div>

          {!locationLoading &&
            <>
              <Helmet>
                <title>{stateLocation.address.countrySubdivisionName} Cannabis Dispensary Directions | {stateLocation.address.countrySubdivisionName} Dispensaries</title>
                <meta name="description" content={`Find Directions to ${stateLocation.address.countrySubdivisionName} Marijuana Dispensaries. ${stateLocation.address.countrySubdivisionName} has nearby Medical and Recreational Cannabis Dispensaries.`} />
              </Helmet>

              <Container maxWidth="lg" className={classes.contentContainer}>
                <Breadcrumbs location={props.location} />
                <PageTitle noMargin variant="h2" component="h1"><Box fontWeight="fontWeightMedium"><span className={classes.textPrimary}>Marijuana dispensaries</span> in {stateLocation.address.countrySubdivisionName}</Box></PageTitle>
              </Container>
            </>
          }

          {(locationLoading || listingsLoading) && <Loading message="Arriving at your destination" />}
          {error && <Error>{error}</Error>}

          {!locationLoading && !listingsLoading && !error && mapData && mapData.length > 0 &&
            <>
              <div className={classes.resultsContainer}>
                <ListingsSearchResults location={stateLocation} categoryType="state" />
              </div>
            </>
          }
          {!locationLoading && !listingsLoading && !error && mapData && mapData.length === 0 &&
            <>
              <Container maxWidth="lg" className={classes.contentContainer}>
                <PageSubtitle>No results found...</PageSubtitle>
              </Container>
            </>
          }

        </div>
      </Layout>
    </>
  )
}
