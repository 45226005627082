export const formatting = {
  getUrlFriendlyName,
  formatPhoneNumber,
  getLinkToSite
};

/**
 * Format a given string to a url friendly name
 * @param  {[type]} name [description]
 * @return {[type]}      [description]
 */
function getUrlFriendlyName(name) {
  let formattedName = name.replaceAll('.', '')
    .replaceAll(' ', '-')
    .replaceAll("'", '')
    .toLowerCase();
  return formattedName;
}

/**
 * Strip querystring from a URL and append PurpleWaze UTM codes.
 * @param {string} url The URL to format.
 * @param {string} campaign The utm_campaign value.  Default profile.
 * @returns {string} The newly formatted URL.
 */
function getLinkToSite(url, campaign = 'profile') {
  let baseUrl = url.split('?')[0];
  return `${baseUrl}?utm_source=purplewaze&utm_medium=referral&utm_campaign=${campaign}`
}

/**
 * format phone numbers for display
 * @param  {[type]} phone [description]
 * @return {[type]}                   [description]
 */
function formatPhoneNumber(phone) {
  if (!phone) {
    return '';
  }
  const match = phone
    .replace(/\D+/g, '').replace(/^1/, '')
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
  return `${part1}${part2}${part3}`;
}
