import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { ListingProfileTabTtitle } from './';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  box: {
    minHeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: theme.border,
    borderRadius:theme.borderRadius,
  },
  menuContainer: {
    marginTop: theme.spacing(2)
  },
  title: {
    margin:theme.spacing(0, 0, 1),
  },
}));

export function ListingMenu({listing, ...props}) {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>{listing.displayName ?? listing.name}® Shop Our Cannabis Pricing Menu {listing.city} Dispensary</title>
        <meta name="description" content={`Shop ${listing.name}’s current cannabis pricing menu. Browse for Marijuana/Cannabis Flower, CBD Products, or Edibles at our ${listing.city} Dispensary.`} />
      </Helmet>
      <ListingProfileTabTtitle>Menu</ListingProfileTabTtitle>
          {(listing.menuEmbedHtml && listing.menuEmbedHtml !== '') ?
              <>
                  <div className={classes.menuContainer} dangerouslySetInnerHTML={{__html: listing.menuEmbedHtml}} />
              </> :
              <Box className={classes.box}>
                <Typography variant="subtitle1" component="h3">Coming Soon!</Typography>
              </Box>
          }
    </>
  )
}
