import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  reviewContainer: {
    padding: theme.spacing(2),
    width: '100%',
    borderTop: theme.border,
    [theme.breakpoints.down('xs')]: {
      padding:theme.spacing(2, 0),
    }
  },
  avatarContainer: {
    marginRight: theme.spacing(1),
  },
  authorName: {
    fontWeight: 800,
  },
  reviewTime: {
    color: theme.palette.text.secondary,
  },
  ratingContainer: {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  ratingText: {
    display: 'inline-block',
    color: theme.palette.text.secondary,
    fontSize:'1rem',
    marginLeft: 5,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    }
  },
  reviewText: {
    margin: 0,
  },
}));

export function ListingReviewListItem({review, ...props}) {
  const classes = useStyles();

  let reviewDate = review.time;

  if (Number(review.time)) {
    const date = moment.unix(review.time);
    reviewDate = date.format('LL');
  } else {
    const date = moment(review.time);
    reviewDate = date.format('LL');
  }

  return (
    <ListItem key={review.id} disableGutters className={classes.reviewContainer}>
        <Box display="flex" flexDirection="row">
          <div className={classes.avatarContainer}>
            <Avatar alt={review.authorName} src={review.profilePhotoUrl} />
          </div>
          <div className={classes.reviewDetailsContainer}>
            <div className={classes.authorName}>{review.authorName}</div>
            <div className={classes.reviewTime}>{reviewDate}</div>
            <div className={classes.ratingContainer}>
              <Rating value={parseFloat(review.rating)} precision={0.5} size="small" readOnly />
              <span className={classes.ratingText}>{parseFloat(review.rating).toFixed(1) || 0.0 }</span>
            </div>
            <Box>
              <p className={classes.reviewText}>{review.text}</p>
            </Box>
          </div>
        </Box>
    </ListItem>
  )
}
