import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { changeLocation, getDeviceLocation } from '../../redux/locationSlice';
import { locationService } from '../../services';
import { formatting } from '../../helpers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme, props) => ({
  box: {
    width:'100%',
    position: 'relative',
  },
  error: {
    display: 'block',
    width: '100%',
    color: theme.palette.error.main,
    fontSize: 14,
    position: 'absolute',
    bottom: -20,
    margin:0,
    left:0,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  input: {
    width: '100%',
  },
  inputRoot: (props) => ({
    position: 'relative',
    background: props.variant === 'grey' ? theme.palette.grey[200] : theme.palette.common.white,
    boxShadow: props.noShadow ? 'none' : theme.defaultShadow,
    borderRadius: theme.shape.borderRadius,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      top: -4.5,
      border: props.variant === 'grey' ? 'none' : `2px solid ${theme.palette.primary.main}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: props.variant === 'grey' ? 'none' : `2px solid ${theme.palette.primary.main}`,
    }
  }),
  button: (props) => ({
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: props.noShadow ? 'none' : theme.defaultShadow,
    '&:hover': {
      boxShadow: props.noShadow ? 'none' : theme.defaultShadow,
    }
  }),
}));

export function LocationSearch(props) {
  const classes = useStyles({noShadow: props.noShadow, variant: props.variant});
  const dispatch = useDispatch();
  const selectedLocation = useSelector(state => state.location.selectedLocation);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);
  const deviceLocation = useSelector(state => state.location.deviceLocation);
  const history = useHistory();

  const Option = (option) => {
    if (option.useExactLocation) {
      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationSearchingIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            <span style={{ fontWeight: 700 }}>Current location</span>
            <Typography variant="body2" color="textSecondary">
              Use your current location
            </Typography>
          </Grid>
        </Grid>
      );
    } else {
      const matches = match(option.address.freeformAddress, inputValue);
      const parts = parse(
        option.address.freeformAddress,
        matches
      );

      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} />
          </Grid>
          <Grid item xs>
            {(matches.length > 0) ?
              parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              )) :
              <span>{option.address.freeformAddress}</span>
            }

            <Typography variant="body2" color="textSecondary">
              {option.address.municipality} | {option.address.countrySubdivision} | {option.address.countryCodeISO3}
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  const fetchPredictions = React.useMemo(
    () =>
      debounce(async (query, callback) => {
        const results = await locationService.getPredictions(query, deviceLocation);
        callback(results);
      }, 200, {leading:false, trailing:true}),
    [deviceLocation],
  );

  React.useEffect(() => {
    let active = true;
    let newOptions = [];
    const currentLocationOption = { useExactLocation:true };

    if (inputValue === '') {
      newOptions = selectedLocation ? [selectedLocation] : [];
      newOptions.unshift(currentLocationOption);
      setOptions(newOptions);
      return undefined;
    }

    fetchPredictions(inputValue, (results) => {
      if (active) {
        if (selectedLocation) {
          newOptions = [selectedLocation];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        newOptions.unshift(currentLocationOption);

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedLocation, inputValue, fetchPredictions, deviceLocation]);

  /**
   * Handle location search action
   * @return {[type]} [description]
   */
  const handleSearch = () => {
    if (selectedLocation) {
      const stateName = formatting.getUrlFriendlyName(selectedLocation.address.countrySubdivisionName);
      const localPath = selectedLocation.entityType === 'PostalCodeArea' ? selectedLocation.address.postalCode : formatting.getUrlFriendlyName(selectedLocation.address.municipality);
      const searchUrl = `/dispensary/us/${stateName}/${localPath}`;

      if (props.onSelect) {
        props.onSelect();
      }
      history.push(searchUrl);

    } else {
      setError('Select a location');
    }
  }

  return (
    <React.Fragment>
     <Grid container spacing={0}>
      <Grid item xs={12}>
          <Box display="flex" alignItems="strech" className={classes.box}>
            <Autocomplete
              id={props.id || 'location-search'}
              className={classes.input}
              classes={{inputRoot:classes.inputRoot}}
              getOptionLabel={(option) => (typeof option === 'string' ? option : (option.useExactLocation) ? '' : option.address.freeformAddress)}
              filterOptions={(x) => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={selectedLocation}
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                if (newValue.useExactLocation) {
                  dispatch(getDeviceLocation())
                    .then(() => {
                      dispatch(changeLocation(deviceLocation.locationDetails));
                    })
                } else {
                  dispatch(changeLocation(newValue));
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params}
                  label={props.variant === 'grey' ? false : 'Add a location'}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  size={props.size ? props.size : 'medium'}
                  error={error}
                />
              )}
              renderOption={Option}
            />
            <Button className={classes.button} variant="contained" color={props.variant === 'grey' ? 'default' : 'primary'} onClick={handleSearch}><SearchIcon fontSize={(props.size === 'small') ? 'medium' : 'large'} /></Button>
            {error &&
              <p className={classes.error}>{error}</p>
            }
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
