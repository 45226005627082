import React, { memo } from 'react';
import clsx from 'clsx';
import { formatting } from '../../helpers';
import { locationService } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import { ListingRating } from './';
import purpleMarkerIcon from '../../assets/purple-marker.svg';
import greenMarkerIcon from '../../assets/green-marker.svg';

const useStyles = makeStyles((theme, isHovered) => ({
  marker: {
    position: 'absolute',
    transform: 'translate(-50%, -100%)',
    width: 30,
    zIndex: 1,
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  markerHovered: {
    zIndex: 3,
  },
  infoBox: {
    display: 'none',
    padding: theme.spacing(1),
    borderRadius: theme.borderRadius,
    background: theme.white,
    position:'absolute',
    transform: 'translateX(-50%)',
    bottom: 45,
    width: 'auto',
    minWidth: 150,
  },
  showInfo: {
    display: 'block',
    zIndex: 3,
  },
  infoBoxTitle: {
    fontSize: 14,
    fontWeight: 500,
    display: 'block',
    marginBottom: 5
  },
}));

function MapMarker({ isHovered, listing, singleItem, $hover, ...props }) {
  const classes = useStyles();
  const listingStateName = locationService.getStateNameByAbbr(listing.stateAbbr);
  const urlStateName = listingStateName && formatting.getUrlFriendlyName(listingStateName);
  const urlCityName = formatting.getUrlFriendlyName(listing.city);
  const profileUrl = `/dispensary/us/${urlStateName}/${urlCityName}/${listing.searchUrlFragment}`;

  return (
    <>
    {listing &&
      <div className={clsx(classes.infoBox, ($hover || isHovered || props.showInfoBox) && classes.showInfo)}>
        <span className={classes.infoBoxTitle}>{listing.name}</span>
        <ListingRating
          listing={listing}
          noPrice
          size="small"
        />
      </div>
    }

    {singleItem ?
      <div className={classes.marker}>
        <img src={purpleMarkerIcon} alt="PurpleWaze Map Marker"/>
      </div> :
      <RouterLink
        className={clsx(classes.marker, ($hover || isHovered) && classes.markerHovered)}
        to={profileUrl}
      >
        <img src={($hover || isHovered) ? greenMarkerIcon : purpleMarkerIcon} alt="PurpleWaze Map Marker"/>
      </RouterLink>
    }
    </>
  )
};

export const ListingMapMarker = memo(MapMarker);
