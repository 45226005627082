import React from 'react';
import LottieAnimation from './Lottie';
import loading from '../../assets/animations/loading-bus.json';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useWindowDimensions } from '../../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    textAlign: 'center',
    padding:theme.spacing(3),
    minHeight: 300,
    [theme.breakpoints.down('md')]: {
      minHeight: 200,
    }
  },
  imgContainer: {
    width: '100%',
    maxWidth: 600,
    margin: '0px auto 10px',
    '& img': {
      display: 'block',
      width: '100%',
      maxWidth: 200,
    }
  }
}));

export function Loading(props) {
  const classes = useStyles();
  const windowDimensions = useWindowDimensions();
  return (
    <>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <Box display="flex" justifyContent="center" flexDirection="column" className={classes.root}>
      {!props.noAnimation &&
        <>
          <div className={classes.imgContainer}>
            <LottieAnimation lotti={loading} height={(windowDimensions.width > 1024) ? 250 : 150} width={(windowDimensions.width > 1024) ? 250 : 150} />
          </div>
          <Typography variant="subtitle1" component="div">{props.message}</Typography>
        </>
      }
      {props.noAnimation &&
        <>
          {props.children}
        </>
      }
    </Box>
    </>
  );
}
