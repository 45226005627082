import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getIpLocation } from './redux/locationSlice';
import { Checkout, CountryCategory, Home, ListingProfile, LocalCategory, Login, Pricing, NotFound, StateCategory, SearchResults } from './views';
import { AgeCheck } from './components/shared';
import CssBaseline from '@material-ui/core/CssBaseline';

function usePageViews() {
  let location = useLocation();

  useEffect(
    () => {
      window.scrollTo(0, 0);
      //ga.send(['pageview', location.pathname])
    },
    [location]
  );
}

const App = () => {
  const dispatch = useAppDispatch();
  const deviceLocation = useAppSelector(state => state.location.deviceLocation);
  const selectedLocation = useAppSelector(state => state.location.selectedLocation);

  useEffect(() => {
    if (!selectedLocation || !deviceLocation) {
      dispatch(getIpLocation());
    }
  }, [dispatch, selectedLocation, deviceLocation]);

  usePageViews();

  return (
    <>
      <CssBaseline />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/search-results/" component={SearchResults} />
        <Route exact path="/dispensary/:countryAbbr" component={CountryCategory} />
        <Route exact path="/dispensary/:countryAbbr/:stateName" component={StateCategory} />
        <Route exact path="/dispensary/:countryAbbr/:stateName/:cityOrPostal" component={LocalCategory} />
        <Route path="/dispensary/:countryAbbr/:stateName/:cityName/:listingUrlFragment" component={ListingProfile} />
        <Route exact path="/checkout" component={Checkout} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/404" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
      <AgeCheck />
    </>
  );
}

export default App;
