export const listings = (stateDetails) => (new Promise((resolve, reject) => {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return (Math.random() * (max - min) + min);
  }

  const latFloor = stateDetails ? Math.floor(stateDetails.lat) : 42;
  const latCeil = stateDetails ? Math.ceil(stateDetails.lat) : 43;
  const lngFloor = stateDetails ? Math.floor(stateDetails.lng) : -71;
  const lngCeil = stateDetails ? Math.ceil(stateDetails.lng) : -72;

  let dummyListings = [];

  for(let i = 0; i < 10; i++) {
    let urlName = `dispensary-${i}`;
    let newListing = {
      id: i,
      name: `Dispensary ${i}`,
      displayName: i % 2 ? `Display Name ${i}` : null,
      address1: '123 Any St',
      city: 'Boston',
      stateAbbr: 'MA',
      state: 'Massachussets',
      postalCode: '02314',
      latitude: getRandomInt(latFloor, latCeil),
      longitude: getRandomInt(lngFloor, lngCeil),
      searchUrlFragment: `/dispensary/us/massachusetts/weymouth/${urlName}`,
      websiteUrl: 'https://www.google.com',
      phoneNumber: '1235551234',
      isBusinessVerified: !!(i % 2),
      googlePriceLevel: '$',
      googleRating: 2.5,
      googleReviews: 25,
      yelpRating: 2.5,
      yelpReviews: 25
    };
    dummyListings.push(newListing);
  }

  setTimeout( function() {
    resolve(dummyListings)
  }, 250)
}));
