import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
  }
}));

export function Error(props) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.error}>
        {props.children}
      </div>
    </>
  );
}
