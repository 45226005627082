import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ListingCard } from './';

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    padding: theme.spacing(0, 0, 3),
  },
  itemContainer: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      padding:theme.spacing(0, 1)
    }
  },
  buttonGroup: {
    position: 'relative',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('md')]: {
      padding:theme.spacing(0, 2)
    }
  },
  button: {
    marginRight: theme.spacing(1),
    boxShadow: 'none'
  }
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 767 },
    items: 3,
    slidesToSlide: 1,
    partialVisibilityGutter: 30,
  },
  mobileXl: {
    breakpoint: { max: 767, min: 464 },
    items: 2,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 30
  }
};

const ButtonGroup = ({ next, previous, goToSlide, totalItems, ...rest }) => {
  const classes = useStyles();
  const { carouselState: { currentSlide } } = rest;

  return (
    <div className={classes.buttonGroup}>
      <Button className={classes.button} variant="contained" color="primary" disabled={currentSlide === 0 ? true : false} onClick={() => previous()}><ChevronLeftIcon /></Button>
      <Button className={classes.button} variant="contained" color="primary" onClick={() => next()}><ChevronRightIcon /></Button>
    </div>
  );
};

export function ListingCardCarousel({listings, ...props}) {
  const classes = useStyles();
  return (
    <>
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        partialVisible={true}
        responsive={responsive}
        infinite={false}
        ssr={false}
        autoPlay={false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass={classes.carouselContainer}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        arrows={false}
        customButtonGroup={<ButtonGroup totalItems={listings.length} deviceType={props.deviceType} />}
        renderButtonGroupOutside={true}
        dotListClass="custom-dot-list-style"
        itemClass={classes.itemContainer}
      >
        {listings.map((listing, index) =>
          <div key={index}>
            <ListingCard data={listing} />
          </div>
        )}
      </Carousel>
    </>
  )
}
