import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { CityCategory, PostalCodeCategory } from 'views';

interface RouteInfo {
  cityOrPostal: string;
}

interface ComponentProps extends RouteComponentProps<RouteInfo> {
}

export const LocalCategory = (props: ComponentProps) => {
  return ( Number.isInteger(parseInt(props.match.params.cityOrPostal)) ?
    <PostalCodeCategory {...props} /> :
    <CityCategory {...props} /> )
};
