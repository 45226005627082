import config from '../config';
import axios from 'axios';
import { listingsConstants } from '../constants';
import { listings } from '../helpers';

export const listingService = {
  getAll,
  getAllByState,
  getAllByCity,
  getAllByPostalCode,
  getOneByUrlFragment,
  getReviews,
};

/**
 * Get all business listings
 * @return {[type]} [description]
 */
function getAll(stateDetails = null) {
  return listings(stateDetails).then(response => response);
}

/**
 * Get listings by state abbr
 * @param  {[type]} stateAbbr [description]
 * @return {[type]}           [description]
 */
function getAllByState(requestConfig) {
  const params = {
    page: requestConfig.page || 1,
    take: listingsConstants.DEFAULT_API_TAKE,
    sort: requestConfig.sort || 'rating',
    order: requestConfig.order || 'DESC'
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.jointApiKey
    },
    params
  };

  if (requestConfig.includeMap) {
    requestOptions.params.includeMap = true;
  }

  return axios.get(`${config.apiUrl}/search/state/${requestConfig.stateAbbr}`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Get all listings by city
 * @param  {[type]} city      [description]
 * @param  {[type]} stateAbbr [description]
 * @return {[type]}           [description]
 */
function getAllByCity(requestConfig) {
  const params = {
    page: requestConfig.page || 1,
    take: listingsConstants.DEFAULT_API_TAKE,
    sort: requestConfig.sort || 'rating',
    order: requestConfig.order || 'DESC'
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.jointApiKey
    },
    params
  };

  if (requestConfig.includeMap) {
    requestOptions.params.includeMap = true;
  }

  return axios.get(`${config.apiUrl}/search/state/${requestConfig.stateAbbr}/${requestConfig.city}`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Get all Listings by zip code
 * @param  {[type]} zip [description]
 * @return {[type]}     [description]
 */
function getAllByPostalCode(requestConfig) {
  const params = {
    page: requestConfig.page || 1,
    take: listingsConstants.DEFAULT_API_TAKE,
    sort: requestConfig.sort || 'rating',
    order: requestConfig.order || 'DESC'
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.jointApiKey
    },
    params
  };

  if (requestConfig.includeMap) {
    requestOptions.params.includeMap = true;
  }

  return axios.get(`${config.apiUrl}/search/postalCode/${requestConfig.postalCode}`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Get one business listing by url fragment
 * @return {[type]} [description]
 */
function getOneByUrlFragment(urlFragment) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.jointApiKey
    },
    params: {
      q: urlFragment,
    }
  };
  return axios.get(`${config.apiUrl}/search/profile`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Get reviews for a given dispensary
 * @param  {[type]} dispensaryId [description]
 * @return {[type]}              [description]
 */
function getReviews(dispensaryId) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Api-Key': config.jointApiKey
    },
    params: {
      dispensaryId: dispensaryId,
    }
  };
  return axios.get(`${config.apiUrl}/search/reviews`, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

/**
 * handle api response
 * @param  {[type]} response [description]
 * @return {[type]}          [description]
 */
function handleResponse(response) {
  if (response.data) {
    return response.data;
  } else {
    return response;
  }
}

/**
 * handle api error
 * @param  {[type]} error [description]
 * @return {[type]}       [description]
 */
function handleError(error) {
  if(error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }
  console.log(error.config);
}
