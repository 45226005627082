import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../assets/logo.png';

const useStyles = makeStyles((theme, props) => ({
  logoContainer: {
      flexGrow: 1,
  },
  logoImg: props => ({
      width: '100%',
      maxWidth: props.logoSize,
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
      }
  }),
}));

export const Logo = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.logoContainer}>
        <img src={logo} alt="PurpleWaze" className={classes.logoImg} />
    </div>
  )
};
