import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import facebookSvg from '../../assets/facebook-icon.svg';
import instagramSvg from '../../assets/instagram-icon.svg';
import twitterSvg from '../../assets/twitter-icon.svg';
import youtubeSvg from '../../assets/youtube-icon.svg';
import linkedInSvg from '../../assets/linkedin-icon.svg';
import {Grid} from "@material-ui/core";

const useStyles = makeStyles((theme, props) => ({
    siteSummaryContainer: {
        padding: theme.spacing(1, 0),
    },
    siteSummary: (props) => ({
        margin: (props.size === 'small') ? theme.spacing(0, 1, 0, 0) : theme.spacing(0, 2, 0, 0),
        '& img': {
            width: '100%',
            maxWidth: (props.size === 'small') ? 20 : 32,
            [theme.breakpoints.down('md')]: {
                maxWidth: (props.size === 'small') ? 14 : 32,
            }
        },
    }),
    siteSummaryCenter: (props) => ({
        margin: (props.size === 'small') ? theme.spacing(0, 1, 0, 0) : theme.spacing(0, 2, 0, 0),
        '& img': {
            width: '100%',
            maxWidth: (props.size === 'small') ? 24 : 32,
            [theme.breakpoints.down('md')]: {
                maxWidth: (props.size === 'small') ? 14 : 32,
            }
        },
    }),
}));

export function ListingSocialMediaLinks({ listing, ...props }) {
    const classes = useStyles({ size:props.size || 'default'});

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.siteSummaryContainer}
            {...props}
        >
            {listing.facebookUrl && listing.facebookUrl !== '' &&
            <Grid item className={clsx(classes.siteSummary, props.justifyContent && props.justifyContent === 'center' && classes.siteSummaryCenter)}>
                <a href={listing.facebookUrl} target="_blank" rel="noreferrer">
                    <img src={facebookSvg} alt="Facebook Logo" />
                </a>
            </Grid>

            }
            {listing.instagramUrl && listing.instagramUrl !== '' &&
            <Grid item className={clsx(classes.siteSummary, props.justifyContent && props.justifyContent === 'center' && classes.siteSummaryCenter)}>
                <a href={listing.instagramUrl} target="_blank" rel="noreferrer">
                    <img src={instagramSvg} alt="Instagram Logo" />
                </a>
            </Grid>
            }
            {listing.twitterUrl && listing.twitterUrl !== '' &&
            <Grid item className={clsx(classes.siteSummary, props.justifyContent && props.justifyContent === 'center' && classes.siteSummaryCenter)}>
                <a href={listing.twitterUrl} target="_blank" rel="noreferrer">
                    <img src={twitterSvg} alt="Twitter Logo" />
                </a>
            </Grid>
            }
            {listing.youtubeUrl && listing.youtubeUrl !== '' &&
            <Grid item className={clsx(classes.siteSummary, props.justifyContent && props.justifyContent === 'center' && classes.siteSummaryCenter)}>
                <a href={listing.youtubeUrl} target="_blank" rel="noreferrer">
                    <img src={youtubeSvg} alt="Youtube Logo" />
                </a>
            </Grid>
            }
          {listing.linkedInUrl && listing.linkedInUrl !== '' &&
          <Grid item className={clsx(classes.siteSummary, props.justifyContent && props.justifyContent === 'center' && classes.siteSummaryCenter)}>
            <a href={listing.linkedInUrl} target="_blank" rel="noreferrer">
              <img src={linkedInSvg} alt="LinkedIn Logo" />
            </a>
          </Grid>
          }
        </Grid>
    )
}
