import React from 'react';
import { dateHelpers } from '../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {ListingOpenClosed} from './';

const useStyles = makeStyles(theme => ({
  hours: {
    maxWidth: 400,
  },
  title: {
    margin:theme.spacing(0, 0, 1),
  },
}));

export function ListingHours({listing, ...props}) {
  const classes = useStyles();
  if (!('hours' in listing) || !listing.hours || listing.hours.length < 1 ) {
    return '';
  }

  const hoursList = listing.hours.map((day, index) => (
    <ListItem key={`${index}`} disableGutters>
      <Grid container spacing={0}>
        <Grid item xs={3}>
          <span>{dateHelpers.getDayLabel(day.dayIndex)}</span>
        </Grid>
        <Grid item xs={9} align="right">
          {day.isClosed &&
            <span>Closed</span>
          }
          {!day.isClosed && day.open && day.close &&
            <span>{dateHelpers.militaryToStandard(day.open)} - {dateHelpers.militaryToStandard(day.close)}</span>
          }
        </Grid>
      </Grid>
    </ListItem>
  ));

  return (
    <>
      {listing.hours && listing.hours.length > 0 &&
      <div className={classes.hours}>
        {props.children}
        {!props.noOpenClosedText &&
        <ListingOpenClosed listing={listing} />
        }
        <List>
          {hoursList}
        </List>
      </div>
      }
    </>
  )
}
