import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  pageSubtitle: {
    margin:theme.spacing(0, 0, 3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
}));

export function PageSubtitle(props) {
  const classes = useStyles();
  return (
    <Typography variant="subtitle1" component="h3" className={classes.pageSubtitle}>{props.children}</Typography>
  );
}
