import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function ListingsListSort({onChange, sortBy, props}) {
  const classes = useStyles();

  const handleChange = (event) => {
    onChange(event.target.value);
  }

  return (
    <>
    <FormControl className={classes.formControl}>
        <InputLabel id="dispensary-listing-sort-select">Sort by</InputLabel>
        <Select
          labelId="dispensary-listing-sort-select"
          id="dispensary-listing-sort-select"
          value={sortBy}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'dispensary-listing-sort-select' }}
        >
          <MenuItem value={'rating'}>Top rated</MenuItem>
          <MenuItem value={'reviews'}>Most reviewed</MenuItem>
          <MenuItem value={'name'}>A-Z</MenuItem>
          <MenuItem value={'-name'}>Z-A</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}
