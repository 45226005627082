import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 500,
    margin:theme.spacing(0, 0, 1),
  },
}));

export function ListingProfileTabTtitle(props) {
  const classes = useStyles();
  return (
    <Typography variant="h4" component="h2" className={classes.title}>{props.children}</Typography>
  )
}
