import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput, TextAreaInput } from './';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '95%',
    maxWidth: 600,
    margin: 'auto',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    boxShadow: 'none',
    '& svg': {
      marginLeft:10,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
}));

 export function ContactForm(props)  {
   const classes = useStyles();
   const { onClose, open } = props;
   const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

   const handleClose = () => {
     onClose();
   };

   return (
     <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.formContainer}>
       <DialogTitle id="contact-form-title">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h4" component="h4">Contact Us</Typography>
          </Box>
          <Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <Box pt={0} pb={3} px={3}>

       <Formik
         initialValues={{
           name: '',
           email: '',
           phone: '',
           message: '',
         }}
         validationSchema={Yup.object({
           name: Yup.string().required('Required'),
           email: Yup.string().email('Invalid email address').required('Required'),
           phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
           message: Yup.string().max(500),
         })}
         onSubmit={(values, { setSubmitting }) => {
           setTimeout(() => {
             alert(JSON.stringify(values, null, 2));
             setSubmitting(false);
           }, 400);
         }}
       >
         {({isSubmitting, ...formik}) => (
             <Form>
               <TextInput
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                required
               />

               <TextInput
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                required
               />

               <TextInput
                id="phone"
                label="Phone"
                name="phone"
                autoComplete="phone"
                required
               />

               <TextAreaInput
                id="message"
                label="Message"
                name="message"
               />

               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 color="primary"
                 size="large"
                 className={classes.submit}
                 disabled={isSubmitting}
               >
                Send <SendIcon />
               </Button>
             </Form>
           )}
        </Formik>
      </Box>
    </Dialog>
   );
 };
