import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
  },
  pageContainer: {
    width: '100%',
    position: 'relative',
  },
  mainContainer: {
    display: 'block',
    marginTop: theme.desktopNavHeight,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.mobileNavHeight,
    }
  }
}));

export default function Layout(props) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Header />
        <main className={classes.mainContainer}>
            {props.children}
        </main>
        <Footer />
      </div>
  );
}
