import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { useWindowDimensions } from '../../hooks';

const useStyles = makeStyles((theme, props) => ({
  ratingContainer: {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  totalReviews: (props) => ({
    color: theme.palette.text.secondary,
    fontSize:(props.size && props.size === 'small') ? 12 : '1rem',
    marginLeft: 5,
    [theme.breakpoints.down('md')]: {
      fontSize:(props.summary) ? '1rem' : 12,
    }
  }),
  ratingText: (props) => ({
    color: theme.palette.text.secondary,
    lineHeight: (props.summary) ? 1 : 'inherit',
    fontSize:(props.size && props.size === 'small') ? 12 : (props.summary) ? '1.75rem' : '1rem',
    marginRight: 5,
    [theme.breakpoints.down('md')]: {
      fontSize:(props.summary) ? '1.5rem' : 12,
    }
  }),
}));

export function ListingRating({listing, ...props}) {
  const classes = useStyles({ size: props.size, summary: props.summary || false });
  const [rating, setRating] = useState(0.0);
  const [totalReviews, setTotalReviews] = useState(0);
  const windowDimensions = useWindowDimensions();
  const [ratingSize, setRatingSize] = useState(props.size || 'medium');

  useEffect(() => {
    if(windowDimensions.width < 1024) {
      if (props.summary) {
        setRatingSize('medium');
      } else {
        setRatingSize('small');
      }
    }
  }, [windowDimensions.width, props.summary])

  useEffect(() => {
    if (listing.rating && listing.reviewCount) {
      setRating(listing.rating);
      setTotalReviews(listing.reviewCount);
    } else {
      if (props.site) {
        // if site prop is set then use reviews from that site
        if (props.site === 'google') {
          setRating(listing.googleRating);
          setTotalReviews(listing.googleReviews);
        } else if (props.site === 'yelp') {
          setRating(listing.yelpRating);
          setTotalReviews(listing.yelpReviewCount);
        }
      } else {
         // else try to use google reviews but then yelp as a backup
        if (listing.googleRating) {
          setRating(listing.googleRating);
          setTotalReviews(listing.googleReviews);
        } else if (listing.yelpRating) {
          setRating(listing.yelpRating);
          setTotalReviews(listing.yelpReviewCount);
        }
      }
    }


  }, [listing, props.site]);

  return (
    totalReviews !== 0 ?
      <div className={classes.ratingContainer}>
        <span className={classes.ratingText}>{parseFloat(rating).toFixed(1) || 0.0 }</span>
        <Rating value={parseFloat(rating)} precision={0.5} size={ratingSize} readOnly />
        <span className={classes.totalReviews}>({totalReviews}) {(listing.googlePriceLevel && !props.noPrice) && `- ${listing.googlePriceLevel}`}</span>
      </div> : ''
  );
}
