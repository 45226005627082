import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {formatting, locationHelper} from "../../helpers";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import RoomIcon from "@material-ui/icons/Room";
import {ContactForm} from "../shared";
import React, {useState} from "react";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme, props) => ({
  ctaRow: (props) => ({
    margin: theme.spacing(0, 0),
    display: 'flex',
    justifyContent: props.justifyContent || 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  }),
  cta: {
    '&:not(last-of-type)': {
      marginRight: 5,
    },
    boxShadow: 'none',
  },
  ctaIcon: {
    marginRight: 3,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export function ListingCTARow({listing, ...props}) {
  const classes = useStyles({justifyContent: props.justifyContent});
  const deviceLocation = useSelector(state => state.location.deviceLocation);
  const [formOpen, setFormOpen] = useState(false);

  const handleContactFormOpen = () => {
    setFormOpen(true);
  };

  const handleContactFormClose = (value) => {
    setFormOpen(false);
  };
  return (
    <div className={classes.ctaRow}>
      {listing.websiteUrl &&
      <Button className={classes.cta} variant="contained" target="_blank"
              href={formatting.getLinkToSite(listing.websiteUrl, 'mapbox')}>
        <PublicIcon className={classes.ctaIcon}/> Website
      </Button>
      }
      {listing.phoneNumber &&
      <Button className={classes.cta} variant="contained" href={`tel:${listing.phoneNumber}`}><PhoneIcon
        className={classes.ctaIcon}/> Call</Button>
      }
      {!props.hideContact &&
      <Button className={classes.cta} variant="contained" onClick={handleContactFormOpen}><EmailIcon
        className={classes.ctaIcon}/> Contact</Button>
      }

      {listing.latitude && listing.longitude &&
      <Button className={classes.cta} variant="contained" target="_blank" href={locationHelper.googleDirectionsUrl({
        origin: deviceLocation && deviceLocation.navigator ? `${deviceLocation.navigator.latitude}, ${deviceLocation.navigator.longitude}` : null,
        destination: `${listing.latitude}, ${listing.longitude}`,
      })}><RoomIcon className={classes.ctaIcon}/> Directions</Button>
      }

      <ContactForm onClose={handleContactFormClose} aria-labelledby="contact-form" open={formOpen}/>
    </div>
  )
}
