import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import listingsReducer from '../redux/listingsSlice';
import locationReducer from '../redux/locationSlice';

const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['location']
};

const locationPersistConfig = {
  key: 'location',
  storage,
};

const rootReducer = combineReducers({
  location: persistReducer(locationPersistConfig, locationReducer),
  listings: listingsReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
