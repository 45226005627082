import React from 'react';
import { LinkRouter } from './';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Breadcrumbs as Crumbs }  from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  breadcrumbsContainer: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    '&:after': {
      content: '""',
      backgroundImage: 'linear-gradient(90deg,rgba(500, 500, 500, 0.1) 0%, rgba(500, 500, 500, 1) 100%)',
      position:'absolute',
      height: '100%',
      width: 50,
      top:0,
      right:-1,
    }
  },
  breadcrumbsOl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    '&::-webkit-scrollbar': {
      display:'none',
    },
  },
  linkText: {
    textTransform: 'capitalize',
    whiteSpace:'nowrap',
    fontSize: '0.875rem',
  },
}))


function formatBreadcrumb(linkTo) {
  let name = linkTo.replace('/dispensary/us/', '');
  const paths = name.split('/');
  return paths[paths.length - 1].replace(/-/, ' ');
}

export const Breadcrumbs = ({location, lastText, ...props}) => {
  const classes = useStyles();
  const toRemove= ['dispensary', 'us'];
  const pathnames = location.pathname.split('/').filter((x) => x);
  const strippedPathnames = pathnames.filter((x) => !toRemove.includes(x));

  return (
    <Crumbs classes={{root:classes.breadcrumbsContainer, ol:classes.breadcrumbsOl}} separator="›" aria-label="breadcrumb">
      <LinkRouter className={classes.linkText} color="inherit" to="/">
        Home
      </LinkRouter>
      {strippedPathnames.map((value, index) => {
        const last = index === strippedPathnames.length - 1;
        const to = `/${pathnames.slice(0, index + (pathnames.length - strippedPathnames.length + 1)).join('/')}`;

        return last ? (
          <Typography className={classes.linkText} color="textPrimary" key={to}>
            {lastText ? lastText : formatBreadcrumb(to)}
            {/*{formatBreadcrumb(to)}*/}
          </Typography>
        ) : (
          <LinkRouter className={classes.linkText} color="inherit" to={to} key={to}>
            {formatBreadcrumb(to)}
          </LinkRouter>
        );
      })}
    </Crumbs>
  );
}
