import React, { useState, useCallback, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListingsListSort, ListingListItem } from './';
import { useSelector, useDispatch } from 'react-redux';
import { fetchListings } from '../../redux/listingsSlice';
import { useWindowDimensions } from "../../hooks";
import InfiniteScroll from 'react-infinite-scroll-component';
import Box from '@material-ui/core/Box';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
  list: {
    padding:0,
  },
  listHeader: {
    background: theme.palette.common.white,
    borderBottom: theme.border,
    zIndex:2,
  },
  resultsText: {
    color: theme.palette.text.primary,
    flexGrow: 1,
  },
  sortContainer: {
    borderLeft: theme.border,
  }
}));

function CustomList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listData = useSelector(state => state.listings.data.businesses);
  const meta = useSelector(state => state.listings.meta);
  const requestConfig = useSelector(state => state.listings.requestConfig);
  const [sortBy, setSortBy] = useState('rating');
  const windowDimensions = useWindowDimensions();

  /**
   * Sort by single property
   * @param  {[type]} property [description]
   * @return {[type]}          [description]
   */
  function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  /**
   * Sort by multiple properties
   * @return {[type]} [description]
   */
  const dynamicSortMultiple = useCallback((sortValue) => {
    const sortProperties = {
      'rating': ['-googleRating', '-yelpRating'],
      'reviews': ['-googleReviews', '-yelpReviewCount'],
      'name': ['name'],
      '-name': ['-name'],
    }
    let props = sortProperties[sortValue];
    return (obj1, obj2) => {
        var i = 0, result = 0, numberOfProperties = props.length;
        while(result === 0 && i < numberOfProperties) {
            result = dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
  }, []);

  const [sortedListings, setSortedListings] = useState(listData.length > 0 ? listData.slice().sort(dynamicSortMultiple(sortBy)) : []);

  const handleSort = useCallback((sortValue) => {
    console.log(`sorting by ${sortValue}`);
    setSortBy(sortValue);
    setSortedListings(sortedListings => sortedListings.slice().sort(dynamicSortMultiple(sortValue)));

    let apiSort = 'rating';
    let apiOrder = 'ASC';

    switch (sortValue.toLowerCase()) {
      case 'name':
        apiSort = 'name';
        apiOrder = 'ASC';
        break;
      case '-name':
        apiSort = 'name';
        apiOrder = 'DESC';
        break;
      case 'reviews':
        apiSort = 'reviews';
        apiOrder = 'DESC';
        break;
      default:
        apiSort = 'rating';
        apiOrder = 'DESC'
    }

    const config = {
      page: 1,
      take: meta.take,
      includeMap: false,
      sort: apiSort,
      order: apiOrder
    };
    const newConfig = Object.assign({}, requestConfig, config);
    dispatch(fetchListings(newConfig));
  }, [dynamicSortMultiple]);

  const fetchMoreData = async () => {
    console.log('fethcing more');
    const config = {
      page: meta.page + 1,
      take: meta.take,
      includeMap: false,
    };
    const newConfig = Object.assign({}, requestConfig, config);
    dispatch(fetchListings(newConfig));
  }

  const listItems = listData.length > 0 &&
      listData.map((listing, index) => (
        <ListingListItem
          key={listing.id}
          listing={listing}
          isHovered={props.hoveredItem === listing.id ? true : false}
          handleItemHover={props.onItemHover}
        />
      ));

  return (
    <>
      <ListSubheader className={classes.listHeader} component={Box}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <span className={classes.resultsText}>Showing {meta.itemCount} results</span>
          <div className={classes.sortContainer}>
            <ListingsListSort sortBy={sortBy} onChange={handleSort} />
          </div>
        </Box>
      </ListSubheader>
      {listItems.length > 0 &&
        <InfiniteScroll
            dataLength={listData.length}
            next={fetchMoreData}
            hasMore={meta.hasNextPage}
            loader={<h4>Loading...</h4>}
            scrollableTarget={windowDimensions.width > 960 ? 'listings-list' : undefined}
        >
          {listItems}
        </InfiniteScroll>
      }


    </>
  );
}

export const ListingsList = memo(CustomList);
