import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { TextInput } from './';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    margin: theme.spacing(2, 'auto'),
    maxWidth: 350,
  },
  formTitle: {
    margin: theme.spacing(2, 0),
  },
  submit: {
    margin: theme.spacing(2, 0),
  },
}));

 export function NewsLetterForm(props)  {
   const classes = useStyles();

   return (
      <Box className={classes.formContainer}>
       <Typography className={classes.formTitle} variant="body1" component="h6">Subscribe to our newsletter to stay up to date on features and releases</Typography>
       <Formik
         initialValues={{
           email: '',
         }}
         validationSchema={Yup.object({
           email: Yup.string().email('Invalid email address').required('Required'),
         })}
         onSubmit={(values, { setSubmitting }) => {
           setTimeout(() => {
             alert(JSON.stringify(values, null, 2));
             setSubmitting(false);
           }, 400);
         }}
       >
         {({isSubmitting, ...formik}) => (
             <Form>
               <TextInput
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                required
               />

               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 color="primary"
                 size="large"
                 className={classes.submit}
                 disabled={isSubmitting}
               >
                Sign up
               </Button>
             </Form>
           )}
        </Formik>
      </Box>
   );
 };
