import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { dateHelpers } from '../../helpers';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ScheduleIcon from '@material-ui/icons/Schedule';

const useStyles = makeStyles(theme => ({
  openText: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  highlightText: {
    display: 'flex',
    alignItems: 'center',
    color: '#23c22a',
    marginRight:5,
  },
  closedText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main,
    marginRight:5,
  },
  clockIcon: {
    marginRight: 3,
  },
}));

export function ListingOpenClosed({listing, ...props}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [nextEndHours, setNextEndHours] = useState(null);
  const [nextStartHours, setNextStartHours] = useState(null);
  const [currentDayIndex, setCurrentDayIndex] = useState(null);

  function nextOpenDayLabel(currentDayIndex, nextOpenDayIndex) {
    if (nextOpenDayIndex - currentDayIndex === 0) {
      return '';
    } else if (nextOpenDayIndex - currentDayIndex === 1 || nextOpenDayIndex - currentDayIndex === 6) {
      return 'tomorrow';
    } else {
      return dateHelpers.getDayLabel(nextOpenDayIndex);
    }
  }

  useEffect(() => {
    if ('hours' in listing && listing.hours && listing.hours.length > 0 ) {
      let d = new Date();
      let currentDay = d.getDay();
      setCurrentDayIndex(currentDay);
      let currentTime = (d.getHours() * 100) + d.getMinutes();

      const listingHours = listing.hours;
      const todaysHours = checkHours(currentDay, listingHours);

      function checkHours(day, listingHours) {
        let hours = null;
        if (listingHours) {
          listingHours.forEach((entry) => {
            if (Number(entry.dayIndex) === day && (entry.open && entry.close) && !entry.isClosed) {
              hours = entry;
            }
          })
        }
        return hours;
      }

      function findNextOpen(currentDay, listingHours) {
        let nextOpenDay = null;
        let foundNextHours = false;
        let nextDay = (currentDay === 6) ? 0 : currentDay + 1;
        for(let i=0; i<=6; i++) {
          const foundHours = checkHours(nextDay, listingHours);
          if(foundHours && !foundNextHours) {
            foundNextHours = true;
            nextOpenDay = foundHours;
            //return dateHelpers.militaryToStandard(foundHours.open);
          } else {
            nextDay = (nextDay === 6) ? 0 : nextDay + 1;
          }
        }
        return nextOpenDay;
      }

      if (todaysHours) {
        let start = Number(todaysHours.open);
        let end = Number(todaysHours.close);
        if(end < 100) {
          end += 2400;
        }

        if (start < currentTime && currentTime < end) {
          //console.log("We're open!");
          setOpen(true);
          setNextEndHours(todaysHours);
        } else if (start > currentTime) {
          //console.log("You're too early to buy weed here today!");
          setNextStartHours(todaysHours);
          setOpen(false);
        } else if (currentTime > end) {
          //console.log("Sorry, it's too late to buy weed from us today! Gonna have to break into the emergency stash tonight!");
          setOpen(false);
          setNextStartHours(findNextOpen(currentDay, listingHours));
        }
      } else {
        //console.log("Sorry, we're not open today! Gonna have to break into the emergency stash!");
        setNextStartHours(findNextOpen(currentDay, listingHours));
      }
    }
  }, [listing]);

  if (!('hours' in listing) || !listing.hours || listing.hours.length < 1 ) {
    return '';
  }

  return (
    open ?
      <Typography className={classes.openText} variant={props.variant || "body1"} component="div">
        <Box display="flex" alignItems="center">
          <span className={classes.highlightText}><ScheduleIcon className={classes.clockIcon} fontSize={props.variant === 'body2' ? 'small' : 'medium'} /> Open</span> Closes at {dateHelpers.militaryToStandard(nextEndHours.close)}
        </Box>
      </Typography> : (nextStartHours) ?
      <Typography className={classes.openText} variant={props.variant || "body1"} component="div">
        <Box display="flex" alignItems="center">
                    <span className={classes.closedText}>
                      <ScheduleIcon className={classes.clockIcon} fontSize={props.variant === 'body2' ? 'small' : 'medium'} /> Closed
                    </span> Opens {nextOpenDayLabel(currentDayIndex, nextStartHours.dayIndex)} at {dateHelpers.militaryToStandard(nextStartHours.open)}
        </Box>
      </Typography> : ''
  );


}
