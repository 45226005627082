import React from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export default function CustomTheme(props) {

  return (
    <ThemeProvider theme={
      createTheme({
        overrides: {
          MuiButton: {
            root: {
              textTransform: 'none',
            },
          },
          MuiCssBaseline: {
            '@global': {
              html: {
                backgroundColor: '#ffffff',
                margin:0,
                width: '100%',
                maxWidth: '100%',
                overflowX: 'hidden'
              },
              body: {
                backgroundColor: '#ffffff',
                margin:0,
                width: '100%',
                maxWidth: '100%',
                overflowX: 'hidden',
              },
            },
          },
        },
        typography: {
          fontSize: 14,
          h1: {
            fontWeight: 600,
            fontSize:"2.5rem",
          },
          h2: {
            fontWeight: 600,
            fontSize:"2rem",
          },
          h3: {
            fontWeight: 500,
            fontSize: "1.75rem",
          },
          h4: {
            fontWeight: 500,
            fontSize: "1.25rem",
          },
          h5: {
            fontWeight: 500,
            fontSize: "1.15rem",
          },
          subtitle1: {
            fontWeight: 300,
            fontSize: "1.25rem",
          },
          subtitle2: {
            fontWeight: 300,
            fontSize: "1rem",
          }
        },
        palette: {
          primary: {
            light: '#f9f9fe',
            main: '#736ADC',
            dark: '#4b33da',
            contrastText: '#FFFFFF',
          },
          secondary: {
            light: '#C3D2D7',
            main:'#547983',
            dark: '#5C7882',
            contrastText: '#ffffff',
          },
          error: {
            light: '#FDE8E6',
            main: '#EA4236',
            dark: '#d32f2f',
            contrastText: '#FFFFFF',
          },
        },
        shape: {
          borderRadius: '8px'
        },
        lightGrey: '#F5F7F8',
        grey: '#5F6368',
        yellow: '#FBBC06',
        lightRed: '#FDE8E6',
        red: '#e05851',
        lightGreen: '#51E07C',
        green: '#23c22a',
        lightBlue: '#AECBFA',
        blue: '#4285F4',
        white: '#ffffff',
        headerFontColor: '#444444',
        bodyFontColor: '#848484',
        fontColor: '#848484',
        primaryBackground: '#FFFFFF',
        secondaryBackground: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '8px',
        defaultShadow: '0px 3px 12px rgba(0,0,0,0.14)',
        desktopNavHeight: 78,
        mobileNavHeight: 105,
        drawerWidth: 240,
      })}
    >
      {props.children}
    </ThemeProvider>
  )
}
