import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Layout from '../app/layout/Layout';
import { Error, Loading, PageTitle, PageSubtitle, SectionTitle, SectionSubtitle } from '../components/shared';
import { ListingCardCarousel } from '../components/listings';
import { LocationSearch } from '../components/location';
import { listingService } from '../services';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Divider, Grid } from '@material-ui/core';
import dispensaryImg from '../assets/dispensary-illustration.svg';
import ediblesImg from '../assets/edibles.png';
import flowerImg from '../assets/flower.png';
import customerHouseImg from '../assets/cannabis-customer-house.svg';
import arrows from '../assets/faded-arrows.svg';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 14
  },
  hero: {
    background: theme.palette.primary.light,
    position: 'relative',
  },
  heroContainer: {
    padding: '50px 0px',
    minHeight: 350,
    [theme.breakpoints.down('sm')]: {
      minHeight:250,
    },
  },
  heroImgContainer: {
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    bottom: -125,
    width: '50%',
    maxWidth: 550,
    [theme.breakpoints.down('md')]: {
      bottom:'-10vw',
      maxWidth: 500,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 325,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 350,
    },
  },
  heroImg: {
    width: "100%",
  },
  pageTitleContainer: {
    position: 'relative',
  },
  heroSearchInput: {
    position: 'relative',
    maxWidth:700,
    [theme.breakpoints.down('md')]: {
      maxWidth:500
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth:400
    }
  },
  arrowsImg: {
    width:150,
    position: 'absolute',
    right: 50,
    bottom: -85,
    '& img': {
      width:'100%',
      display: 'block',
    }
  },
  searchButtonContainer: {
    margin: theme.spacing(2, 0),
  },
  heroSearchBtn: {
    fontSize: 18
  },
  useLocationBtn: {
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.common.white,
      boxShadow: theme.shadows[3],
    },
  },
  section: {
    position: 'relative',
  },
  sectionContainer: {
    padding:theme.spacing(5, 0),
    [theme.breakpoints.down('md')]: {
      padding:theme.spacing(3, 1),
    },
  },
  sectionTitleContainer: {
    margin: theme.spacing(0, 0, 2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 0, 1),
    }
  },
  featuredListingsSection: {
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5vw'
    }
  },
  findWazeSection: {
    minHeight: 500,
    [theme.breakpoints.down('sm')]: {
      minHeight: 300
    }
  },
  ctaBarImg: {
    position: 'absolute',
    '& img': {
      width: '100%',
      display: 'block'
    }
  },
  ediblesImg: {
    width:550,
    right: 150,
    top:-150,
    [theme.breakpoints.down('sm')]: {
      width:350,
      top:-90,
      right:100
    },
    [theme.breakpoints.down('xs')]: {
      width:250,
      top:0,
      right:20
    }
  },
  flowerImg: {
    width:260,
    right: 0,
    top:-50,
    [theme.breakpoints.down('sm')]: {
      width:150,
      right: 20,
      top: -20
    },
    [theme.breakpoints.down('xs')]: {
      width:120,
      top:20,
      right:20
    }
  },
  customerHouseImg: {
    width: '50%',
    maxWidth:800,
    right: 0,
    bottom:0,
    [theme.breakpoints.down('sm')]: {

    },
    [theme.breakpoints.down('xs')]: {

    }
  },
  btnWhite: {
    background:theme.palette.common.white,
    color: theme.palette.primary.main,
    fontSize: 20,
    marginTop: theme.spacing(2),
    boxShadow: 'none'
  },
  bgLightGrey: {
    background: theme.lightGrey,
  },
  bgPrimary: {
    background: theme.palette.primary.main,
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  textWhite: {
    color: theme.palette.common.white,
  }
}));

export function Home(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [listings, setListings] = useState([]);
  const selectedLocation = useSelector(state => state.location.selectedLocation);

  useEffect(() => {
    const fetchListings = async () => {
      let results = [];
      if (selectedLocation) {
        console.log(selectedLocation.address);
        const apiResults = await listingService.getAllByCity({
          includeMap: false,
          city: selectedLocation.address.municipality,
          stateAbbr: selectedLocation.address.countrySubdivision
        });
        results = apiResults.data.businesses;
      } else {
        results = await listingService.getAll();
      }

      setListings(results);
      setLoading(false);
    };

    fetchListings();
  }, []);

  return (
    <>
    <Helmet>
      <title>PurpleWaze® Find the Best Cannabis Dispensaries Near You</title>
      <meta name="description" content={`Find the best Medical and Recreational Marijuana Dispensaries near you. Find the best dispensaries, order ahead and shop current cannabis prices.`} />
    </Helmet>
    <Layout>
      <Grid container spacing={0}>
        {/* Hero */}
        <Grid item xs={12} className={classes.hero}>
          <div className={classes.heroImgContainer}>
            <img className={classes.heroImg} src={dispensaryImg} alt="Customer finding local dispensary" />
          </div>
          <Container maxWidth="lg">
            <Grid container spacing={0} alignItems="center" className={classes.heroContainer}>
              {/* Hero title */}
              <Grid item xs={12} className={classes.pageTitleContainer}>
                  <PageTitle>Find your <span className={classes.textPrimary}>dispensary.</span></PageTitle>
                  <PageSubtitle>Enter your location to find dispensaries near you.</PageSubtitle>
                  <div className={classes.heroSearchInput}>
                    <div className={classes.arrowsImg}>
                      <img alt="arrows" src={arrows} />
                    </div>
                    <LocationSearch id="hero-location-search" {...props} />
                  </div>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {/* Section: Featured Listings */}
        <Grid item xs={12} className={clsx(classes.section, classes.featuredListingsSection)}>
          <Container maxWidth="lg">
            <Grid container spacing={0} className={classes.sectionContainer}>
              {/* Section title */}
              <Grid item xs={12} className={classes.sectionTitleContainer}>
                <SectionTitle>Shop <span className={classes.textPrimary}>dispensaries.</span></SectionTitle>
                <SectionSubtitle>Showing stores near {selectedLocation ? selectedLocation.address.freeformAddress : "you"}</SectionSubtitle>
              </Grid>
              {/* Section content */}
              <Grid item xs={12}>
                  {loading && <Loading noAnimation>Loading listings...</Loading>}
                  {error && <Error>{error}</Error>}
                  {listings && listings.length > 0 &&
                    <ListingCardCarousel listings={listings} />
                  }
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {/* Featured Listings */}

        {/* Section: CTA Bar */}
        <Grid item xs={12} className={clsx(classes.section, classes.ctaBarSection, classes.bgPrimary)}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={clsx(classes.ctaBarImg, classes.ediblesImg)}>
              <img src={ediblesImg} alt="Edibles" />
            </div>
            <div className={clsx(classes.ctaBarImg, classes.flowerImg)}>
              <img src={flowerImg} alt="Flower" />
            </div>
            <Grid container spacing={0} className={classes.sectionContainer}>
              <Grid item xs={12} className={classes.sectionTitleContainer}>
                <SectionTitle><span className={classes.textWhite}>Featured Brands</span></SectionTitle>
                <SectionSubtitle><span className={classes.textWhite}>Find your favorite products</span></SectionSubtitle>
                <Button size="large" variant="contained" className={classes.btnWhite}>Search</Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>


        {/* Section: Strains */}
        <Grid item xs={12} className={clsx(classes.section, classes.strainsSection)}>
          <Container maxWidth="lg">
            <Grid container spacing={0} className={classes.sectionContainer}>
              {/* Section title */}
              <Grid item xs={12} className={classes.sectionTitleContainer}>
                <SectionTitle>Shop <span className={classes.textPrimary}>strains.</span></SectionTitle>
                <SectionSubtitle>Showing stores near {selectedLocation ? selectedLocation.address.freeformAddress : "you"}</SectionSubtitle>
              </Grid>
              {/* Section content */}
              <Grid item xs={12}>
                  {loading && <Loading noAnimation>Loading listings...</Loading>}
                  {error && <Error>{error}</Error>}
                  {listings && listings.length > 0 &&
                    <ListingCardCarousel listings={listings} />
                  }
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {/* Strains */}

        {/* Divider */}
        <Grid item xs={12}>
          <Container maxWidth="lg">
            <Divider />
          </Container>
        </Grid>

        {/* Section: Deals */}
        <Grid item xs={12} className={clsx(classes.section, classes.dealsSection)}>
          <Container maxWidth="lg">
            <Grid container spacing={0} className={classes.sectionContainer}>
              {/* Section title */}
              <Grid item xs={12} className={classes.sectionTitleContainer}>
                <SectionTitle>Shop <span className={classes.textPrimary}>deals.</span></SectionTitle>
                <SectionSubtitle>Showing stores near {selectedLocation ? selectedLocation.address.freeformAddress : "you"}</SectionSubtitle>
              </Grid>
              {/* Section content */}
              <Grid item xs={12}>
                  {loading && <Loading noAnimation>Loading listings...</Loading>}
                  {error && <Error>{error}</Error>}
                  {listings && listings.length > 0 &&
                    <ListingCardCarousel listings={listings} />
                  }
              </Grid>
            </Grid>
          </Container>
        </Grid>
        {/* Deals */}

        {/* Section: CTA Bar */}
        <Grid item xs={12} className={clsx(classes.section, classes.bgLightGrey)}>
          <div className={clsx(classes.ctaBarImg, classes.customerHouseImg)}>
            <img src={customerHouseImg} alt="Cannabis customer house" />
          </div>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={0} alignItems="center" className={clsx(classes.findWazeSection, classes.sectionContainer)}>
              <Grid item xs={12} sm={7} className={classes.sectionTitleContainer}>
                <SectionTitle>The path to Purple<span className={classes.textPrimary}>Waze.</span></SectionTitle>
                <SectionSubtitle variant="subtitle2">Purple Waze aims to connect cannabis customers with the best, top-rated marijuana dispensaries in their area.</SectionSubtitle>
                <SectionSubtitle variant="subtitle2">After spending time on other dispensary locator sites we found there was a lot left to be desired. Most sites seemed to be motivated by generating advertising dollars from local marijuana dispensaries instead of using their technology to guide customers to the best marijuana business destinations.</SectionSubtitle>
                <SectionSubtitle variant="subtitle2">Our goal was to create a trust-worthy, customer-focused dispensary location application that would be beneficial to cannabis businesses and customers alike. Thank you for choosing to use Purple Waze!</SectionSubtitle>
                <Button size="large" component={RouterLink} variant="contained" color="primary" to="/pricing">Why us</Button>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Layout>
    </>
  )
}
