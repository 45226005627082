import React, { useState, useEffect } from 'react';
import { cookies } from '../../helpers';
import { userConstants } from '../../constants';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export function AgeCheck(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkCookie = () => {
        let ageCheck = cookies.getCookie(userConstants.AGE_COOKIE_NAME);
        if (ageCheck === '') {
            setOpen(true);
        }
    };

    checkCookie();
  }, []);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== 'escapeKeyDown') {
      setOpen(false);
    }
  }

  const handleYes = () => {
    cookies.setCookie(userConstants.AGE_COOKIE_NAME, true, 365);
    handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you at least 21 years of age or a valid medical marijuana patient?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            PurpleWaze strives to abide by all state laws and regulations in regards to access to cannabis.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined">
            No
          </Button>
          <Button onClick={handleYes} color="primary" variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
