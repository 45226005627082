import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../app/layout/Layout';
import { Breadcrumbs, Error, Loading, PageTitle, PageSubtitle } from '../components/shared';
import { ListingsSearchResults } from '../components/listings';
import { locationService } from '../services';
import { fetchListings, clearListings } from '../redux/listingsSlice';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    }
  },
  resultsContainer: {
    padding: 0,
    borderTop: theme.border,
    borderBottom: theme.border,
  },
  textPrimary: {
    color: theme.palette.primary.main,
  }
}));

export function CityCategory(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mapData = useSelector(state => state.listings.data.mapLocations);
  const error = useSelector(state => state.listings.error);
  const [listingsLoading, setListingsLoading] = useState(true);
  const [locationLoading, setLocationLoading] = useState(true);
  const [stateLocation, setStateLocation] = useState(null);
  const [cityLocation, setCityLocation] = useState(null);
  const stateName = props.match.params.stateName;
  const cityName = props.match.params.cityOrPostal;

  React.useEffect(() => {

    const stateDetails = locationService.getStaticStateInfo(stateName);
    if (!stateDetails) {
      return props.history.push('/404');
    }
    setStateLocation(stateDetails);

    locationService.getCityDetails(cityName, stateDetails.abbr)
      .then(response => {
        if(!response) {
          return props.history.push('/404');
        }
        setCityLocation(response);
        setLocationLoading(false);

        const requestConfig = {
          requestType: 'city',
          city: response.address.municipality,
          stateAbbr: stateDetails.abbr,
          includeMap: true,
        };
        dispatch(fetchListings(requestConfig))
          .then(() => {
            setListingsLoading(false);
          });
      })
      .catch(err => console.log(err));

      return () => {
        dispatch(clearListings());
      }

  }, [props.history, stateName, cityName, dispatch]);

  return (
    <>
      <Layout>
        <div>

          {!locationLoading &&
            <>
              <Helmet>
                <title>{cityLocation.address.municipality}, {cityLocation.address.countrySubdivision} Cannabis Dispensaries | {cityLocation.address.municipality}, {cityLocation.address.countrySubdivision} Dispensaries</title>
                <meta name="description" content={`Find Directions to ${cityLocation.address.municipality}, ${cityLocation.address.countrySubdivision} Marijuana Dispensaries. ${cityLocation.address.municipality}, ${cityLocation.address.countrySubdivision} has nearby Medical and Recreational Cannabis Dispensaries.`} />
              </Helmet>

              <Container maxWidth="lg" className={classes.contentContainer}>
                <Breadcrumbs location={props.location} />
                <PageTitle noMargin variant="h2" component="h1"><span className={classes.textPrimary}>Marijuana dispensaries</span> near {cityLocation.address.municipality}, {cityLocation.address.countrySubdivision}</PageTitle>
              </Container>
            </>
          }

          {(locationLoading || listingsLoading) && <Loading message="Arriving at your destination" />}
          {error && <Error>{error}</Error>}

          {!locationLoading && !listingsLoading && !error && mapData && mapData.length > 0 &&
            <>
              <div className={classes.resultsContainer}>
                <ListingsSearchResults location={cityLocation} />
              </div>
            </>
          }
          {!locationLoading && !listingsLoading && !error && mapData && mapData.length === 0 &&
            <>
              <Container maxWidth="lg" className={classes.contentContainer}>
                <PageSubtitle>No results found...</PageSubtitle>
              </Container>
            </>
          }

        </div>
      </Layout>
    </>
  )
}
