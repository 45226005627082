import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import CustomTheme from './app/CustomTheme';
import reportWebVitals from './reportWebVitals';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || ''
};

TagManager.initialize(tagManagerArgs);

let persistor = persistStore(store);
//persistor.purge();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CustomTheme>
          <BrowserRouter>
              <App />
          </BrowserRouter>
        </CustomTheme>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();
