import React, { memo } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { formatting } from '../../helpers';
import { locationService } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import logo from '../../assets/logo-ghost-icon.svg';
import {ListingCategories, ListingRating, ListingOpenClosed, ListingCTARow} from './';
import {ListingVerifiedIcon} from "./ListingVerifiedIcon";
import {Box, ButtonBase} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    display: 'block',
    borderBottom: theme.border,
    borderTop: '1px solid transparent',
    borderRight: '1px solid transparent',
    borderLeft: '1px solid transparent',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    }
  },
  listItemHovered: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  actionArea: {
    padding: theme.spacing(2, 1, 1),
    display: 'block',
    width: '100%',
  },
  buttonArea: {
    padding: theme.spacing(1, 1, 2),
    display: 'block',
    width: '100%',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.15rem',
  },
  infoRow: {
    margin: '5px 0px',
  },
  avatarLogo: {
    width:'100%',
    objectFit: 'contain',
    display: 'block'
  },
  avatar: {
    width:'100%',
    height: 100,
    objectFit: 'cover',
    display: 'block'
  },
  textContainer: {
    padding: theme.spacing(0, 2),
  },
}));


function CustomListItem({listing, isHovered, ...props}) {
  const classes = useStyles();
  const listingStateName = locationService.getStateNameByAbbr(listing.stateAbbr);
  if (listingStateName) {
    const urlStateName = formatting.getUrlFriendlyName(listingStateName);
    const urlCityName = formatting.getUrlFriendlyName(listing.city);
    const profileUrl = `/dispensary/us/${urlStateName}/${urlCityName}/${listing.searchUrlFragment}`;

    return (
        <ListItem
            className={clsx(classes.listItem, isHovered && classes.listItemHovered)}
            onMouseEnter={() => props.handleItemHover(listing.id)}
            onMouseLeave={() => props.handleItemHover(null)}
        >
          <ButtonBase
            className={classes.actionArea}
            component={RouterLink}
            to={profileUrl}
          >
            <Grid container spacing={0}>

              <Grid item xs={4} md={3}>
                {listing.logoUrl ?
                  <img className={classes.avatarLogo} src={listing.logoUrl} alt={`${listing.name} cannabis dispensary`} /> :
                  <img className={classes.avatar} src={(listing.photoUrls && listing.photoUrls.length > 0) ? listing.photoUrls[0] : logo} alt={`${listing.name} cannabis dispensary`} />
                }
              </Grid>

              <Grid item xs={8} md={9} className={classes.textContainer}>
                <div className={classes.titleRow}>
                  <Typography className={classes.title} variant="h4" component="h3">{listing.name} <ListingVerifiedIcon listing={listing} /></Typography>
                </div>
                <div className={classes.infoRow}>
                  <ListingRating
                    listing={listing}
                    size="small"
                  />
                </div>
                <div className={classes.infoRow}>
                  <Typography variant="body2" component="p">{listing.address1} {listing.city}, {listing.stateAbbr} {listing.postalCode}</Typography>
                </div>
                <div className={classes.infoRow}>
                  <ListingOpenClosed listing={listing} variant="body2" />
                </div>
                <div className={classes.infoRow}>
                  <ListingCategories listing={listing} />
                </div>
              </Grid>
            </Grid>
          </ButtonBase>
          <Box
          className={classes.buttonArea}
          >
            <ListingCTARow listing={listing} hideContact justifyContent="flex-end" />
          </Box>

        </ListItem>
    );
  } else {
    return '';
  }
}

export const ListingListItem = memo(CustomListItem);
