import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip } from "@material-ui/core";
import VerifiedIcon from '@material-ui/icons/VerifiedUser';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.primary.main,
    paddingTop: 3
  }
}));

export function ListingVerifiedIcon({ listing, ...props }) {
  const classes = useStyles();

  if (listing.isBusinessVerified || listing.isJointClient) {
    return (
      <Tooltip title="Verified Business">
        <VerifiedIcon className={classes.icon} fontSize="small" />
      </Tooltip>
    )
  }
  return null;
}
